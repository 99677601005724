import React, { useEffect, useRef, useState } from "react";
import {
  useModal,
  ModalComponentProps,
  useCancellableModal,
} from "react-hooks-async-modal";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./modal.scss";
import SchemaFormPage, { SchemaFormParameter } from "components/SchemaFormPage";
import { Value } from "react-hook-schema-form";
import { modalCancelEvent } from "./modalCancelEvent";
import QRCode from "qrcode";
import { useLocalStorage } from "./storage";
import { useForm } from "react-hook-form";
import { useFormatter } from "./intl";
import { ConsumerLocaleSelector } from "./localeSelector";
import { Locale, StringIntl } from "utils/locale";
import { stagingAlertName } from "utils/domain";

export const useModalWithRegister = <ModalPropsT, ReturnedType = void>(
  ModalComponent: React.ComponentType<
    ModalComponentProps<ReturnedType> & ModalPropsT
  >
): ((props: ModalPropsT) => Promise<ReturnedType>) => {
  const callCancelableModal = useCancellableModal<ModalPropsT, ReturnedType>(
    ModalComponent
  );
  return (props) => {
    const [promise, canceller] = callCancelableModal(props);
    modalCancelEvent.on(canceller);
    return (async () => {
      try {
        const response = await promise;
        return response;
      } finally {
        modalCancelEvent.off(canceller);
      }
    })();
  };
};

const confirm = ({
  onResolve,
  message,
}: ModalComponentProps<boolean> & { message: string | JSX.Element }) => {
  return (
    <Modal show={true} onHide={() => onResolve(false)}>
      <Modal.Header closeButton>
        <Modal.Title>確認</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => onResolve(false)}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => onResolve(true)}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export const useConfirm = () => {
  return useModalWithRegister<{ message: string | JSX.Element }, boolean>(
    confirm
  );
};

const alert = ({
  onResolve,
  message,
}: ModalComponentProps<void> & { message: string }) => {
  return (
    <Modal show={true} onHide={() => onResolve()}>
      <Modal.Header closeButton>
        <Modal.Title>確認</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={() => onResolve()}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export const useAlert = () => {
  return useModalWithRegister<{ message: string }>(alert);
};

const policy = ({
  onResolve,
  message,
  availableLocales,
}: ModalComponentProps<void> & {
  message: StringIntl;
  availableLocales?: Locale[];
}) => {
  const { formatMessage, formatString } = useFormatter();
  return (
    <Modal show={true} className="policy">
      <Modal.Header>
        <Modal.Title>
          {formatMessage("PrivacyPolicy.Title")}
          <p style={{ fontSize: "0.7em" }}>
            {formatMessage("PrivacyPolicy.Text")}
          </p>
          <div style={{ fontSize: "0.7em" }}>
            <ConsumerLocaleSelector locales={availableLocales || []} />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflow: "auto", height: "70vh" }}>
        <p style={{ whiteSpace: "pre-wrap" }}>{formatString(message)}</p>
        <Button variant="primary" onClick={() => onResolve()}>
          OK
        </Button>
      </Modal.Body>
    </Modal>
  );
};
export const usePolicy = () => {
  return useModalWithRegister<{
    message: StringIntl;
    availableLocales?: Locale[];
  }>(policy);
};

const contract = ({
  title,
  notice,
  message,
  buttonLabelOK,
  buttonLabelCancel,
  onResolve,
}: ModalComponentProps<boolean> & {
  title: StringIntl;
  notice: string;
  message: StringIntl;
  buttonLabelOK: string;
  buttonLabelCancel: string;
}) => {
  const { formatMessage, formatString } = useFormatter();
  return (
    <Modal show={true} className="policy">
      <Modal.Header>
        <Modal.Title>
          {title}
          <p style={{ fontSize: "0.7em" }}>{notice}</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflow: "auto", height: "70vh" }}>
        <p style={{ whiteSpace: "pre-wrap" }}>{formatString(message)}</p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <Button
            variant="light"
            onClick={() => onResolve(false)}
            style={{ border: "1px solid #ccc" }}
          >
            {buttonLabelCancel}
          </Button>
          <Button variant="primary" onClick={() => onResolve(true)}>
            {buttonLabelOK}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export const useContract = () => {
  return useModalWithRegister<
    {
      title: StringIntl;
      notice: string;
      message: StringIntl;
      buttonLabelOK: string;
      buttonLabelCancel: string;
    },
    boolean
  >(contract);
};

const download = ({
  onResolve,
  message,
  url,
  filename,
}: ModalComponentProps<boolean> & {
  message: string | JSX.Element;
  url: string;
  filename?: string;
}) => {
  return (
    <Modal show={true} onHide={() => onResolve(false)}>
      <Modal.Header closeButton>
        <Modal.Title>確認</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => onResolve(false)}>
          Cancel
        </Button>
        <a
          className="btn btn-primary"
          href={url}
          onClick={(e) => onResolve(true)}
          download={filename}
        >
          OK
        </a>
      </Modal.Footer>
    </Modal>
  );
};

export const useDownload = () => {
  const dialog = useModalWithRegister<
    { message: string | JSX.Element; url: string; filename?: string },
    boolean
  >(download);
  return async (
    params: (
      | {
          blob: Blob;
        }
      | {
          data: BlobPart[];
          mimeType: string;
        }
    ) & { filename?: string }
  ) => {
    const blob = (() => {
      if ("blob" in params) {
        return params.blob;
      } else {
        return new Blob(params.data, { type: params.mimeType });
      }
    })();
    const url = URL.createObjectURL(blob);
    const filename = params.filename;
    await dialog({ message: "ダウンロード", url, filename });
    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 1000);
  };
};

const modalForm = ({
  onResolve,
  params,
  value,
}: ModalComponentProps<Value> & {
  params: SchemaFormParameter;
  value: Value;
}) => {
  return (
    <Modal show={true} className="policy" onHide={() => onResolve(undefined)}>
      <Modal.Header closeButton>
        {params.title && <Modal.Title>{params.title}</Modal.Title>}
      </Modal.Header>
      <Modal.Body style={{ overflow: "auto", height: "70vh" }}>
        <SchemaFormPage
          data={value || {}}
          parameter={{
            ...params,
            title: undefined,
            edit: {
              ...params.edit,
              confirm: false,
              handler: async (value) => {
                onResolve(value);
              },
            },
          }}
        />
      </Modal.Body>
    </Modal>
  );
};
export const useModalForm = (): (<T = Value>(props: {
  params: SchemaFormParameter;
  value: Value;
}) => Promise<T>) => {
  return useModalWithRegister<
    { params: SchemaFormParameter; value: Value },
    Value
  >(modalForm);
};

const toost = ({
  onResolve,
  message,
  duration,
}: ModalComponentProps<void> & { message: string; duration: number }) => {
  useEffect(() => {
    setTimeout(onResolve, duration);
  }, []);
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        textAlign: "center",
        background: "#fccc",
        zIndex: 1000,
      }}
    >
      {message}
    </div>
  );
};
export const useToost = () => {
  return useModal<{ message: string; duration: number }>(toost);
};

const modalQrCode = ({
  onResolve,
  value,
}: ModalComponentProps<Value> & {
  value: Value;
}) => {
  const url = value && new URL(value);
  const [additionalSearchParams, setAdditionalSearchParams] =
    useLocalStorage<string>("qrCodeAdditionalSearchParams", "");
  const mergedSearchParams = new URLSearchParams(
    Object.fromEntries(
      new Map([
        ...new URLSearchParams(url.searchParams).entries(),
        ...new URLSearchParams(additionalSearchParams).entries(),
      ])
    )
  ).toString();
  const mergedUrl =
    url &&
    new URL(
      `${url.origin}${url.pathname}${
        mergedSearchParams ? "?" + mergedSearchParams : ""
      }`
    ).toString();
  return (
    <Modal show={true} className="policy" onHide={() => onResolve(undefined)}>
      <Modal.Header closeButton>
        <Modal.Title>QR Code</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflow: "auto", height: "70vh" }}>
        <div>
          <input
            type="text"
            value={additionalSearchParams}
            onChange={(e) => setAdditionalSearchParams(e.target.value)}
          />
        </div>
        <div>
          <QRCodeComponent value={mergedUrl} />
        </div>
        <div>
          <a
            style={{ wordBreak: "break-all" }}
            href={mergedUrl}
            target="_blank"
            rel="noreferrer"
          >
            {mergedUrl}
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export const useModalQrCode = (): (<T = Value>(props: {
  value: Value;
}) => Promise<T>) => {
  return useModalWithRegister<{ value: Value }, Value>(modalQrCode);
};

export const QRCodeComponent = ({ value }: { value: string }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      QRCode.toCanvas(canvas, value);

      if (stagingAlertName) {
        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        // キャンバスの大きさを取得
        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;

        const text = "Sample";
        const fontSize = 20; // 例: 30px
        ctx.font = `${fontSize}px Arial`;
        ctx.fillStyle = "black";

        // テキストの幅と高さを取得
        const textMetrics = ctx.measureText(text);
        const textWidth = textMetrics.width;
        const textHeight = fontSize; // フォントサイズを直接利用

        const rectWidth = textWidth * 1.2;
        const rectHeight = textHeight * 1.2;

        // 矩形の開始位置を計算
        const rectX = (canvasWidth - rectWidth) / 2;
        const rectY = (canvasHeight - rectHeight) / 2;

        // 矩形を描画
        ctx.fillStyle = "white";
        ctx.fillRect(rectX, rectY, rectWidth, rectHeight);

        // テキストの開始位置を計算
        const startX = (canvasWidth - textWidth) / 2;
        const startY = (canvasHeight - textHeight) / 2 + textHeight * 0.8; // ベースラインの調整のため0.8を乗算

        // テキストを描画
        ctx.fillStyle = "black";
        ctx.fillText(text, startX, startY);
      }
    }
  }, [value]);
  return <canvas ref={canvasRef}></canvas>;
};
