export const removeKey = <P, K extends keyof P>(obj: P, key: K) => {
  const copied = { ...obj };
  delete copied[key];
  return copied as Pick<P, Exclude<keyof P, K>>;
};

export const removeKeys = <P, K extends (keyof P)[]>(obj: P, keys: K) => {
  const copied = { ...obj };
  for (const key of keys) {
    delete copied[key];
  }
  return copied as Pick<P, Exclude<keyof P, K[number]>>;
};

export type SrcObject = {
  protocol?: string;
  host?: string;
  path?: string;
  contentType?: string;
  url?: string;
};

export const decodeSrc = (src?: string) => {
  const parsedUrl = parseUrl(src);
  const { protocol, host, path, searchParams } = parseUrl(src);
  if (src && protocol === "data") {
    const contentTypeMatch = src.match(/data:([a-z0-9/+-]+);/);
    const contentType = contentTypeMatch ? contentTypeMatch[1] : undefined;
    return {
      protocol,
      contentType,
      url: src,
    };
  } else {
    const { contentType } = extractParams(searchParams, ["contentType"]);
    return {
      protocol,
      host,
      path,
      contentType,
      url: src ? unparseUrl(parsedUrl) : undefined,
    };
  }
};

export const encodeSrc = (obj: SrcObject) => {
  const { protocol, host, path, contentType, url: urlString } = obj;
  const url = parseUrl(urlString || `${protocol}://${host}${path}`);
  if (url.searchParams) {
    mergeParams(url.searchParams, { contentType });
    return unparseUrl(url);
  } else {
    // data URL
    return urlString;
  }
};

const parseUrl = (
  src?: string
): {
  protocol?: string;
  host?: string;
  path?: string;
  searchParams?: URLSearchParams;
} => {
  const match = src?.match?.(/^([a-z-]+):\/\/([a-z.-]+)([^?#]+)(?:\?([^#]+))?/);
  if (match) {
    const [_, protocol, host, path, search] = match;
    return {
      protocol,
      host,
      path,
      searchParams: new URLSearchParams(search),
    };
  }
  const matchNohost = src?.match?.(/^([a-z-]+):(.*)/);
  if (matchNohost) {
    const [_, protocol, path] = matchNohost;
    return { protocol, path };
  }
  return {};
};

const unparseUrl = ({
  protocol,
  host,
  path,
  searchParams,
}: {
  protocol?: string;
  host?: string;
  path?: string;
  searchParams?: URLSearchParams;
}) => {
  const searchString = searchParams?.toString();
  return `${protocol}://${host}${path}${
    searchString ? "?" : ""
  }${searchString}`;
};

const mergeParams = (
  params: URLSearchParams,
  obj: Record<string, string | undefined>
) => {
  for (const [key, value] of Object.entries(obj)) {
    if (value) {
      params.append(key, value);
    }
  }
};

const extractParams = <K extends string[]>(
  params: URLSearchParams | undefined,
  keys: K
) => {
  const obj: { [P in K[number]]?: string } = {};
  if (params) {
    for (const key of keys as K[number][]) {
      const value = params.get(key);
      if (value) {
        obj[key] = value;
      }
      params.delete(key);
    }
  }
  return obj;
};
