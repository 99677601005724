import { ObjectSchema } from "schemaComponents";

const schema: ObjectSchema = {
  schemaType: "object",
  properties: [
    {
      title: "テンプレート名",
      propertyName: "mailTemplateName",
      schema: {
        schemaType: "text",
        required: true,
      },
    },
    {
      title: "設定済み",
      propertyName: "mailTemplateId",
      schema: {
        schemaType: "boolean",
        required: true,
      },
    },
    {
      title: "表題",
      propertyName: "subject",
      schema: {
        schemaType: "text",
        required: true,
      },
    },
  ],
};
export default schema;
