import React from "react";
import { ObjectSchema } from "schemaComponents";
import getListSchema from "./schema";
import getSchema from "../item/schema";
import { timestampsProperties, mergeSchema } from "schemaComponents/util";
import { clientDomain } from "utils/domain";
import { resolveRoute } from "pages";

export const getDataTransferSchema = ({
  clientId,
  isMaster,
  isAuthSkipped,
}: {
  clientId: string;
  isMaster: boolean;
  isAuthSkipped: boolean;
}): ObjectSchema => {
  return mergeSchema({ schemaType: "object", properties: [] }, [
    {
      title: "事業所ID",
      propertyName: "shopId",
      schema: {
        schemaType: "text",
        required: true,
      },
    },
    ...getListSchema({ clientId }).properties,
    ...(
      getSchema({
        isEditing: false,
        clientId,
        isMaster,
        isAuthSkipped,
      }) as ObjectSchema
    ).properties,
    ...timestampsProperties,
  ]);
};

export const getDataTransferSchemaForClient = ({
  clientId,
}: {
  clientId: string;
}): ObjectSchema => {
  return {
    schemaType: "object",
    properties: [
      {
        title: "事業所名",
        propertyName: "shopName",
        schema: {
          schemaType: "textIntl",
          required: true,
        },
      },
      {
        title: "ロゴ",
        propertyName: "logoURL",
        schema: {
          schemaType: "image",
          maxHeight: 30,
        },
      },
      {
        title: "調査",
        propertyName: "surveyId",
        schema: {
          uiType: "normal",
          schemaType: "externalKey",
          resourcePath: "/survey",
          titleKey: "surveyName",
          query: { filter: { clientId } },
        },
      },
      {
        title: "URL",
        propertyId: "url",
        schema: {
          schemaType: "url",
          readOnly: true,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          calc: (item: any) => {
            const { shopId, isAuthSkipped } = item;
            return isAuthSkipped
              ? shopId &&
                  `${clientDomain}${resolveRoute("CONSUMER_PUBLIC_ENQUETE", {
                    shopId,
                  })}`
              : shopId &&
                  `${clientDomain}${resolveRoute("CONSUMER_ENTRY", {
                    shopId,
                  })}`;
          },
        },
      },
      {
        title: "アンケート開始日時",
        propertyName: "enqueteStartAt",
        schema: {
          schemaType: "datetime",
        },
      },
      {
        title: "アンケート終了日時",
        propertyName: "enqueteEndAt",
        schema: {
          schemaType: "datetime",
        },
      },
      {
        title: "住所",
        propertyName: "shopAddress",
        schema: {
          schemaType: "text",
        },
      },
      {
        title: "地域",
        propertyName: "region",
        schema: {
          schemaType: "text",
        },
      },
    ],
  };
};
