import { useMemo } from "react";
import stringify from "fast-json-stable-stringify";
import { ObjectSchema, PropertyType, Schema } from "schemaComponents";
import {
  getListFromMultipleQueries,
  useMultipleQueryResource,
} from "utils/legacyFirestore";
import type { FilterParams, QueryParams } from "common/comodel-firestore";

type ResourceDefinition = {
  resourcePath: string;
  titleKey: string;
  query?: QueryParams;
};

const getResourceKey = ({
  resourcePath,
  titleKey,
  query,
}: ResourceDefinition) => {
  return stringify([resourcePath, titleKey, query]);
};

export const usePropertyListStatic = (schema: ObjectSchema) => {
  const propertyList = useMemo(
    () => Array.from(extractProperties(schema)),
    [schema]
  );
  return propertyList;
};

export const usePropertyList = (schema: ObjectSchema) => {
  const propertyList = useMemo(
    () => Array.from(extractProperties(schema)),
    [schema]
  );

  const resourceRequests = useMemo(
    () =>
      Object.fromEntries(
        (function* () {
          for (const property of propertyList) {
            const schema = property.schema;
            if (
              schema.schemaType === "externalKey" ||
              schema.schemaType === "multipleExternalKey"
            ) {
              yield [
                getResourceKey(schema),
                {
                  resourcePath: schema.resourcePath,
                  titleKey: schema.titleKey,
                  query: schema.query,
                  filter: schema.filter,
                },
              ];
            }
          }
        })()
      ),
    [propertyList]
  );

  const resources = useMultipleQueryResource(resourceRequests);

  return useMemo(() => {
    const convertedPropertyList = Array.from(propertyList).map((property) => {
      const schema = property.schema;
      if (
        schema.schemaType === "externalKey" ||
        schema.schemaType === "multipleExternalKey"
      ) {
        const resourceKey = getResourceKey(schema);
        if (resources?.[resourceKey]?.list) {
          return {
            ...property,
            schema: {
              ...property.schema,
              schemaType:
                schema.schemaType === "externalKey"
                  ? "selector"
                  : "multipleSelector",
              options: resources[resourceKey]?.list,
            },
          } as PropertyType & { propertyName: string };
        }
      }
      return property;
    });
    return convertedPropertyList;
  }, [propertyList, resources]);
};

export const getPropertyList = async (schema: ObjectSchema) => {
  const propertyList = Array.from(extractProperties(schema));

  const resourceRequests = Object.fromEntries(
    (function* () {
      for (const property of propertyList) {
        const schema = property.schema;
        if (
          schema.schemaType === "externalKey" ||
          schema.schemaType === "multipleExternalKey"
        ) {
          yield [
            getResourceKey(schema),
            {
              resourcePath: schema.resourcePath,
              titleKey: schema.titleKey,
              query: schema.query,
            },
          ];
        }
      }
    })()
  );

  const resources = await getListFromMultipleQueries(resourceRequests);

  const convertedPropertyList = Array.from(propertyList).map((property) => {
    const schema = property.schema;
    if (
      schema.schemaType === "externalKey" ||
      schema.schemaType === "multipleExternalKey"
    ) {
      const resourceKey = getResourceKey(schema);
      if (resources[resourceKey]?.list) {
        return {
          ...property,
          schema: {
            ...property.schema,
            schemaType:
              schema.schemaType === "externalKey"
                ? "selector"
                : "multipleSelector",
            options: resources[resourceKey]?.list,
          },
        } as PropertyType & { propertyName: string };
      }
    }
    return property;
  });
  return convertedPropertyList;
};

const extractProperties = function* (
  schema: ObjectSchema,
  path = ""
): Generator<PropertyType & { propertyName: string }> {
  for (const property of schema?.properties || []) {
    if (!property.propertyName) {
      continue;
    }
    if (property.schema.schemaType !== "object") {
      yield { ...property, propertyName: `${path}${property.propertyName}` };
    } else {
      for (const item of extractProperties(
        property.schema,
        `${path}${property.propertyName}.`
      )) {
        yield item;
      }
    }
  }
};
