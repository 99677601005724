import React, { useMemo } from "react";
import schema from "./schema";
import getDataTransferSchema from "./dataTransferSchema";
import SchemaListPage, {
  SchemaListPageParameter,
} from "components/SchemaListPage";
import { useRewardList } from "models/hook";

import { REWARDS } from "../route";
import { resolveRoute } from "pages";
import { rewardData } from "common/models/reward";
import { useConditionSelector } from "hooks/condition/conditionSelector";
import { useAdminAuthentication } from "hooks/auth";
import { useAdminPageAuthorization } from "hooks/authAdminPage";

export const LIST_REWARDS = REWARDS.sub("", Rewards);

function Rewards({ clientId }: { clientId: string }) {
  useAdminPageAuthorization(clientId);
  const { role } = useAdminAuthentication();
  const isMaster = role === "master";
  const { conditionSelector, condition, timeCondition } = useConditionSelector({
    schema: getDataTransferSchema({ isMaster }),
    hideTimeCondition: true,
  });
  const { list, loading, upsert, get } = useRewardList({
    clientId,
    createdAt: timeCondition,
    condition,
  });
  const parameter: SchemaListPageParameter<rewardData> = useMemo(
    () => ({
      title: "リワード一覧",
      edit: {
        path: ({ rewardId }) =>
          resolveRoute("UPDATE_REWARD", { rewardId, clientId }),
      },
      create: {
        path: () => resolveRoute("ADD_REWARD", { clientId }) + "?edit=1",
        isDisabled: !isMaster,
      },
      schema,
      download: {
        schema: getDataTransferSchema({ isMaster }),
        isDisabled: !isMaster,
        filename: `rewards-${clientId}`,
        handler: get,
      },
      upload: {
        schema: getDataTransferSchema({ isMaster }),
        handler: upsert,
        isDisabled: !isMaster,
      },
    }),
    [clientId]
  );
  return (
    <SchemaListPage
      parameter={parameter}
      list={list}
      loading={loading}
      header={conditionSelector}
    ></SchemaListPage>
  );
}

export default Rewards;
