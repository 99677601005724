import dayjs from "dayjs";
import { IntlMessageFormat } from "intl-messageformat";
import {
  AvailableLocale,
  Locale,
  MessageId,
  StringIntl,
  messages,
} from "utils/locale";
import { useContextLocale } from "components/LocaleProvider";

const formatString = (
  str?: string,
  values?: Record<string, string | number>
) => {
  if (!values) {
    return str;
  } else if (str) {
    const format = new IntlMessageFormat(str);
    const output = format.format(values);
    return typeof output === "string" ? output : undefined;
  } else {
    return undefined;
  }
};

export const useFormatter = () => {
  const [locale] = useContextLocale();
  const getMessage = (id?: MessageId, defaultMessage?: string) => {
    return (
      (id && (messages[locale as AvailableLocale]?.[id] || messages.ja[id])) ||
      defaultMessage
    );
  };
  const dateFormatter = (
    id: MessageId,
    defaultMessage: string,
    value: number | null | undefined
  ) => {
    const dateFormat = getMessage(id, defaultMessage);
    return value && dayjs(value).format(dateFormat);
  };
  return {
    formatDate: dateFormatter.bind(null, "DateFormat", "YYYY-MM-DD"),
    formatDateTime: dateFormatter.bind(
      null,
      "DateTimeFormat",
      "YYYY-MM-DD HH:mm:ss"
    ),
    formatMessage: (id: MessageId, values?: Record<string, string>) => {
      const message = getMessage(id);
      return formatString(message, values) || "";
    },
    formatString: (
      value: StringIntl | undefined,
      id?: MessageId,
      values = {}
    ) => {
      const message = resolveStringIntl(value, locale) || getMessage(id);
      return formatString(message, values);
    },
  };
};

export type StringIntlResolver = {
  (value: StringIntl): string;
  (value: StringIntl | undefined): string | undefined;
};

// formatStringと重複しているので、削除を検討。
export function useStringIntl() {
  const [locale] = useContextLocale();
  return ((value: StringIntl | undefined) => {
    return resolveStringIntl(value, locale);
  }) as StringIntlResolver;
}

export const resolveStringIntl = (
  value: StringIntl | undefined,
  locale?: Locale
) => {
  if (typeof value === "string") {
    return value;
  } else if (!value) {
    return undefined;
  } else {
    return value[locale || "ja"] || value?.ja;
  }
};
