import React from "react";
import { Button, Form } from "react-bootstrap";

import { shopData } from "common/models/shop";
import { InjectedHTML } from "components/InjectedHTML";
import { useStringIntl } from "hooks/intl";

export const ConsumerReview = ({
  template,
  shopData,
  commentData,
}: {
  template: string;
  shopData?: Partial<shopData> | null;
  commentData?: string;
}) => {
  const s = useStringIntl();
  return (
    <InjectedHTML
      value={template}
      params={{
        shopName: s(shopData?.shopName) || "",
      }}
      elements={{
        comment: (
          <div style={{ margin: "10px" }}>
            <Form.Control
              as="textarea"
              value={commentData || ""}
              style={{
                maxWidth: "200px",
                display: "inline-block",
                verticalAlign: "top",
                resize: "none",
              }}
              readOnly
              disabled
            />
            <Button
              variant="outline-secondary"
              style={{
                display: "inline-block",
                verticalAlign: "top",
                marginLeft: "5px",
              }}
              onClick={() => {
                navigator.clipboard.writeText(commentData || "");
              }}
              data-tooltip-content="クリップボードにコピー"
            >
              コピー
            </Button>
          </div>
        ),
        links: (
          <div
            style={{
              margin: "8px",
              // padding: "3px",
              border: "1px solid #ccc",
            }}
          >
            {shopData?.enqueteCompleteLinks?.map((item, index) => {
              if (item.type === "tripAdvisor") {
                return (
                  <div
                    key={index}
                    style={{
                      margin: "10px",
                      lineHeight: "200%",
                    }}
                  >
                    <a href={item.url} target="_blank" rel="noreferrer">
                      <img
                        style={{ maxWidth: "24px" }}
                        src="https://static.tacdn.com/img2/brand_refresh/Tripadvisor_logoset_solid_green.svg"
                        alt="Tripadvisor"
                      />
                      <span>TripAdviserで口コミを書き込む</span>
                    </a>
                  </div>
                );
              } else if (item.type === "googleMap") {
                return (
                  <div
                    key={index}
                    style={{
                      margin: "10px",
                      lineHeight: "200%",
                    }}
                  >
                    <a href={item.url} target="_blank" rel="noreferrer">
                      <img
                        style={{ maxWidth: "24px" }}
                        src="https://lh3.googleusercontent.com/gYxrWReRGr9RV-g5UHKe6k-ylOPylohrcQ-hyBSMnEcZYpXgLtrqnRt9vDroWQpl2F_dztC4M4svFnxpGcGASETOru6C8RGDSvzR"
                        alt=""
                      />
                      <span>Google Mapsで口コミを書き込む</span>
                    </a>
                  </div>
                );
              } else {
                return <> </>;
              }
            })}
          </div>
        ),
      }}
    ></InjectedHTML>
  );
};
