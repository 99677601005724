import React, { forwardRef, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import en from "date-fns/locale/en-US";
import ja from "date-fns/locale/ja";
import zhTw from "date-fns/locale/zh-TW";

import "./DatePicker.scss";
import { useFormatter } from "hooks/intl";
import classNames from "classnames";
import { useContextLocale } from "components/LocaleProvider";
import FormClearButton from "components/FormClearButton";

registerLocale("en", en);
registerLocale("ja", ja);
registerLocale("zh", zhTw);
registerLocale("zh-tw", zhTw);

const isMobile = navigator.userAgent.match(
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/
);

const CustomInput = forwardRef(
  (
    {
      value,
      onClick,
      className,
    }: {
      value: string | undefined | null;
      className: string;
      onClick: React.MouseEventHandler<HTMLDivElement>;
    },
    ref
  ) => (
    <div className={className} onClick={onClick}>
      {value}&nbsp;
    </div>
  )
);
CustomInput.displayName = "CustomDatePickerInput";

export const DatePickerStyled = ({
  date,
  onChange,
  className,
}: {
  date: Date | null;
  onChange: (value: number | null) => void;
  className: string;
}) => {
  const onDateChangedHandler = (value: Date) =>
    onChange(value && value.getTime());
  const [locale] = useContextLocale();
  const { formatMessage } = useFormatter();
  const [isOpen, setIsOpen] = useState(false);
  const clear = () => onChange(null);
  return (
    <>
      <DatePicker
        locale={locale}
        dateFormat={formatMessage("Calendar.DateFormat")}
        dateFormatCalendar={formatMessage("Calendar.YearMonthFormat")}
        selected={date}
        onChange={onDateChangedHandler}
        onCalendarOpen={() => setIsOpen(true)}
        onCalendarClose={() => setIsOpen(false)}
        className={classNames(className, isOpen && "focused")}
        customInput={
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          isMobile ? <CustomInput {...({} as any)} /> : undefined
        }
      />
      <FormClearButton
        onClick={clear}
        style={{ position: "absolute", top: "0", right: "0" }}
      />
    </>
  );
};

export const DateTimePickerStyled = ({
  date,
  onChange,
  className,
}: {
  date: Date | null;
  onChange: (value: number | null) => void;
  className: string;
}) => {
  const onDateChangedHandler = (value: Date) =>
    onChange(value && value.getTime());
  const [locale] = useContextLocale();
  const { formatMessage } = useFormatter();
  const [isOpen, setIsOpen] = useState(false);
  const clear = () => onChange(null);
  return (
    <>
      <DatePicker
        locale={locale}
        dateFormat={formatMessage("Calendar.DateTimeFormat")}
        dateFormatCalendar={formatMessage("Calendar.YearMonthFormat")}
        selected={date}
        onChange={onDateChangedHandler}
        onCalendarOpen={() => setIsOpen(true)}
        onCalendarClose={() => setIsOpen(false)}
        className={classNames(className, isOpen && "focused")}
        showTimeInput
        customInput={
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          isMobile ? <CustomInput {...({} as any)} /> : undefined
        }
      />
      <FormClearButton
        onClick={clear}
        style={{ position: "absolute", top: "0", right: "0" }}
      />
    </>
  );
};

export const MonthPickerStyled = ({
  date,
  onChange,
  className,
}: {
  date: Date | null;
  onChange: (value: number) => void;
  className: string;
}) => {
  const onDateChangedHandler = (value: Date) =>
    onChange(value ? value.getTime() : Date.now());
  const [locale] = useContextLocale();
  const { formatMessage } = useFormatter();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <DatePicker
      locale={locale}
      dateFormat={formatMessage("Calendar.YearMonthFormat")}
      dateFormatCalendar={formatMessage("Calendar.YearMonthFormat")}
      selected={date}
      onChange={onDateChangedHandler}
      onCalendarOpen={() => setIsOpen(true)}
      onCalendarClose={() => setIsOpen(false)}
      className={classNames(className, isOpen && "focused")}
      showMonthYearPicker
      customInput={
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        isMobile ? <CustomInput {...({} as any)} /> : undefined
      }
    />
  );
};
