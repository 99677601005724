import { StringIntlResolver } from "hooks/intl";
import { SchemaOfType, SchemaTypes } from "schemaComponents";

export type OptionDefinition = {
  handler?: (
    searchInput: string
  ) => undefined | { optionLabel: string; optionValue?: unknown };
  valueHandler?: (value: unknown) => {
    optionLabel: string;
    optionValue?: unknown;
  };
  optionLabel?: string;
  operator?: string;
  optionValue?: unknown;
};

export const schemaHandler: {
  [K in SchemaTypes]?: (
    schema: SchemaOfType<K>,
    s: StringIntlResolver,
    searchInput?: string
  ) => (OptionDefinition | undefined)[];
} = {
  selector: (schema, s) =>
    schema.options.map((option) => ({
      optionLabel: s(option.title),
      optionValue: option.value,
    })),

  multipleSelector: (schema, s) =>
    schema.options.map((option) => ({
      optionLabel: s(option.title),
      optionValue: option.value,
    })),
  numberSelector: (schema) => {
    return Array(schema.maxValue - schema.minValue + 1)
      .fill(0)
      .map((_, index) => ({
        optionLabel: `${schema.minValue + index}に等しい`,
        optionValue: schema.minValue + index,
      }));
  },
  boolean: () => [
    { optionLabel: "はい", operator: "$truthy", optionValue: true },
    { optionLabel: "いいえ", operator: "$truthy", optionValue: false },
  ],
  number: () => {
    return [
      {
        handler: (str) => {
          const value = parseInt(str);
          return !isNaN(value)
            ? { optionLabel: `${value}以上`, value, optionValue: value }
            : undefined;
        },
        operator: "$gte",
      },
      {
        handler: (str) => {
          const value = parseInt(str);
          return !isNaN(value)
            ? { optionLabel: `${value}未満`, value, optionValue: value }
            : undefined;
        },
        operator: "$lt",
      },
    ];
  },
  text: (schema) => [
    !schema.required
      ? {
          optionLabel: "書き込みあり",
          operator: "$nin",
          optionValue: [null, ""],
        }
      : undefined,
    {
      handler: (str) => ({
        optionLabel: `${str}を含む`,
      }),
      operator: "$regex",
    },
    { handler: (str) => ({ optionLabel: `${str}に一致` }) },
  ],
  multilineText: (schema) => [
    !schema.required
      ? {
          optionLabel: "書き込みあり",
          operator: "$nin",
          optionValue: [null, ""],
        }
      : undefined,
    {
      handler: (str) => ({ optionLabel: `${str}を含む` }),
      operator: "$regex",
    },
  ],
  textIntl: (schema) => [
    {
      handler: (str) => ({
        optionLabel: `${str}を含む`,
      }),
      operator: "$matchIntl",
    },
  ],
  multilineTextIntl: (schema) => [
    {
      handler: (str) => ({ optionLabel: `${str}を含む` }),
      operator: "$matchIntl",
    },
  ],
};

/* eslint-disable @typescript-eslint/no-explicit-any */
// (schemaHandler as any).conversion = (schema: any) =>
//   schema.options.map((option: any) => ({
//     optionLabel: option.title,
//     optionValue: option.value,
//   }));
/* eslint-enable @typescript-eslint/no-explicit-any */
