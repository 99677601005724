import React, { ElementType, useEffect, useMemo, useState } from "react";
import firebaseApp from "firebaseApp";
import { getAuth } from "firebase/auth";
import { decodeSrc, removeKey } from "./util";

const auth = getAuth(firebaseApp);

export type MediaSpecifier = string | undefined;

export type MediaProps<P> = Pick<P, Exclude<keyof P, "src">> & {
  src: MediaSpecifier;
  elementType: ElementType<P>;
};

export const Media = <P extends { src?: string }>(props: MediaProps<P>) => {
  const { src, elementType } = props;
  const metadata = useMetadata(src);
  const copiedProps = removeKey({ ...props }, "elementType");
  const Element = elementType as ElementType<{ src?: string }>;
  return metadata ? <Element {...copiedProps} src={metadata.url} /> : <></>;
};

export const MediaWithMimeType = <
  P extends { src?: string; mimeType?: string }
>(
  props: MediaProps<P>
) => {
  const { src, elementType } = props;
  const metadata = useMetadata(src);
  const copiedProps = removeKey({ ...props }, "elementType");
  const Element = elementType as ElementType<{ src?: string }>;
  return metadata ? (
    <Element
      {...copiedProps}
      src={metadata.url}
      mimeType={metadata.contentType}
    />
  ) : (
    <></>
  );
};

const useMetadata = (src?: MediaSpecifier) => {
  const [metadata, setMetadata] = useState<
    | {
        url?: string;
        contentType?: string;
      }
    | undefined
  >(undefined);
  const parsedUrl = useMemo(() => {
    const parsedUrl = decodeSrc(src);
    const { protocol, url, contentType } = parsedUrl;
    if (url && protocol?.match(/https?|data/)) {
      setMetadata({ url, contentType });
    } else if (!url) {
      setMetadata(undefined);
    }
    return parsedUrl;
  }, [src]);
  useEffect(() => {
    const { protocol, path, host } = parsedUrl;
    let unmounted = false;
    if (protocol === "gs" && path && host) {
      (async () => {
        if (!path) {
          return;
        }
        const metadata = await getMetadata({ path, host });
        if (!unmounted) {
          setMetadata(metadata);
        }
      })();
    }
    return () => {
      unmounted = true;
    };
  }, [src]);
  if (!src) {
    return undefined;
  } else {
    return metadata;
  }
};

const getMetadata = async ({ path, host }: { path: string; host: string }) => {
  const objectUrl = `https://firebasestorage.googleapis.com/v0/b/${host}/o/${encodeURIComponent(
    path.replace(/^\//, "")
  )}`;
  const response = await fetch(objectUrl, {
    headers: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      authorization: `Firebase ${(auth?.currentUser as any)?.accessToken}`,
    },
  });
  const metaData: { downloadTokens: string; contentType: string } =
    await response.json();
  const { downloadTokens, contentType } = metaData;
  const url = `${objectUrl}?alt=media&token=${downloadTokens}`;
  return { url, contentType };
};
