import { defineCollection } from "../comodel-firestore";
import { DateRange } from "./type";

export type industryData = {
  industryId: string;
  industryName?: string;
  description?: string;
  logoUrl?: string;
  createdAt?: number;
  privacyPolicy?: string;
  invalidationNotificationText?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  questions?: any;
  dataId: string;
};

export const industryDefinition = defineCollection({
  collectionName: "industry",
  idKey: "industryId",
  constraints: ["industryId"],
  queryDefs: {
    list: ({
      createdAt,
      condition,
    }: {
      createdAt?: DateRange;
      condition?: Record<string, unknown>;
    }) => {
      return {
        queryParams: {
          filter: { createdAt },
          orderBy: [["createdAt", "desc"]],
        },
        filterParams: condition,
      };
    },
  },
  defaultValue: {} as industryData,
  onGenerate: (o) => ({ ...o, createdAt: Date.now() }),
  onWrite: (o) => ({ ...o, updatedAt: Date.now() }),
});
