import React, { useMemo } from "react";

import schema from "./schema";
import SchemaListPage, {
  SchemaListPageParameter,
} from "components/SchemaListPage";
import { useClientList } from "models/hook";
import { CLIENTS } from "../route";
import { resolveRoute } from "pages";
import { useAdminAuthentication } from "hooks/auth";
import { clientData } from "common/models/client";
import { useConditionSelector } from "hooks/condition/conditionSelector";

export const LIST_CLIENTS = CLIENTS.sub("", Clients);

function Clients() {
  const { conditionSelector, condition, timeCondition } = useConditionSelector({
    schema,
    hideTimeCondition: true,
  });
  const { user, role } = useAdminAuthentication();
  const isMaster = role === "master";
  const { list, loading, get } = useClientList({
    clientIds: user?.role !== "master" ? user?.clientIds : undefined,
    createdAt: timeCondition,
    condition,
  });
  const parameter: SchemaListPageParameter<clientData> = useMemo(
    () => ({
      title: "クライアント一覧",
      edit: {
        path: ({ clientId }) => resolveRoute("LIST_ENQUETES", { clientId }),
      },
      create: {
        path: () => resolveRoute("ADD_CLIENT") + "?edit=1",
        isDisabled: !isMaster,
      },
      download: {
        isDisabled: !isMaster,
        filename: `clients`,
        handler: get,
      },
      schema,
    }),
    []
  );
  return (
    <SchemaListPage
      parameter={parameter}
      list={list}
      loading={loading}
      header={conditionSelector}
    ></SchemaListPage>
  );
}

export default Clients;
