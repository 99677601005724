export type PSeriesData = {
  name: string;
  type: "date" | "month" | "timestamp" | "id" | "string" | "unknown";
  dataset?: string;
  categories: string[];
} & (
  | {
      codes: (number | null)[];
      values?: (string | number | null)[];
    }
  | {
      codes?: undefined;
      values: (string | number | null)[];
    }
);

export type PDataFrameData = {
  columns: PSeriesData[];
  indexes: PSeriesData[];
  rowCount: number;
};

class NamedList<T extends { name: string }> {
  indexMap: Record<string, number>;
  nameMap: Record<string, T>;
  constructor(private items: T[]) {
    this.nameMap = Object.fromEntries(items.map((item) => [item.name, item]));
    this.indexMap = Object.fromEntries(
      items.map((item, index) => [item.name, index])
    );
  }

  getByName(name: string) {
    return this.nameMap[name];
  }

  getIndex(name: string) {
    return this.indexMap[name];
  }

  map<U>(
    callbackfn: (value: T, index: number, array: T[]) => U,
    thisArg?: unknown
  ): U[] {
    return this.items.map(callbackfn, thisArg);
  }

  size() {
    return this.items.length;
  }

  [Symbol.iterator]() {
    return this.items[Symbol.iterator]();
  }
}

export class PSeries {
  name;
  type;
  dataset;
  categories;
  codes;
  values: (string | number | null)[] = [];
  isIndex;
  spans: number[] = [];
  private reverseCategory: { [K in string]: number } = {};
  constructor(
    public data: PSeriesData,
    isIndex?: boolean,
    formerSpans?: number[]
  ) {
    this.isIndex = isIndex;
    this.name = data.name;
    this.type = data.type;
    this.categories = data.categories;
    this.dataset = data.dataset;
    if (!data.values && !data.codes) {
      console.error(data);
      throw new Error("PSeriesData must have either values or codes");
    }
    if (data.categories) {
      this.reverseCategory = Object.fromEntries(
        data.categories.map((v, i) => [v, i])
      );
      this.values =
        data.values ||
        (data.codes?.map((v) => (v == null ? null : data.categories[v])) as (
          | string
          | number
          | null
        )[]);
      this.codes =
        data.codes ||
        this.values.map((value) => this.reverseCategory[value as string]);
    } else {
      this.codes = undefined;
      this.values = data.values || (data.codes as (string | number | null)[]);
    }
    if (isIndex) {
      const spans: number[] = [0];
      let index = 0;
      let prev = this.values[0];
      this.values.forEach((value, i) => {
        if (value === prev && !formerSpans?.[i]) {
          spans[index] += 1;
        } else {
          index = i;
          spans[index] = 1;
          prev = value;
        }
      });
      this.spans = spans;
    }
  }

  // 内部コードを振りなおす。カテゴリーが存在しない場合は末尾に追加する
  // ソートは行わないので別途ソートが必要。
  reorderIndex(categories: string[]) {
    if (!this.categories || !this.codes) {
      return;
    }
    const oldCategories = this.categories;
    this.categories = categories;
    this.reverseCategory = Object.fromEntries(categories.map((v, i) => [v, i]));
    const map = oldCategories.map((v, i) => this.reverseCategory[v]);
    this.codes = this.codes.map((v) => {
      if (v == null) return null;
      if (map[v] != null) return map[v];
      // 存在しないカテゴリーは末尾に追加
      const value = oldCategories[v];
      this.categories.push(value);
      this.reverseCategory[value] = this.categories.length - 1;
      map[v] = this.categories.length - 1;
      return map[v];
    });
  }

  get(index: number) {
    return this.values[index];
  }

  getCode(index: number) {
    return (this.codes || this.values)?.[index];
  }

  size() {
    return this.values.length;
  }
}

type MultilevelMap<T> = Map<string | number, MultilevelValue<T>>;
type MultilevelValue<T> = T | MultilevelMap<T>;

const sortWithLevels = (
  values: (string | number | null)[][],
  levels: number[]
) => {
  const compare = (
    a: (string | number | null)[],
    b: (string | number | null)[]
  ) => {
    for (let i = 0; i < levels.length; i++) {
      const level = levels[i];
      const aValue = a[level] ?? -1;
      const bValue = b[level] ?? -1;
      const result = aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      if (result !== 0) {
        return result;
      }
    }
    return 0;
  };
  return values.sort(compare);
};

const extractLevelKeys = (
  keys: (string | number | null)[][],
  levels: number[]
) => {
  const sorted = sortWithLevels(keys, levels);
  let prev: (string | number | null)[] = [];
  const resultKeys = [];
  // eslint-disable-next-line no-debugger
  // debugger;
  for (let i = 0; i < sorted.length; i++) {
    const row = levels.map((level) => sorted[i][level]);
    if (row.every((item, i) => prev[i] === item)) {
      continue;
    }
    resultKeys.push(row);
    prev = row;
  }
  return resultKeys;
};

class MultilevelIndex {
  rowCount: number = 0;
  levels: PSeries[] = [];
  private treeIndex?: MultilevelMap<number>;
  keys: (string | number | null)[][] = [];
  levelReverseMap: { [K in string]: number } = {};
  levelMap: { [K in string]: PSeries } = {};

  static fromLevels(levels: PSeries[]) {
    const index = new MultilevelIndex();
    index.levels = levels;
    index.rowCount = levels[0].size();
    index.recalculateLevelIndex();
    index.recalculateIndex();
    return index;
  }

  recalculateIndex() {
    // levelsからtreeIndexを構築
    this.treeIndex = new Map();
    for (let i = 0; i < this.rowCount; i++) {
      const keys = this.levels.map((series) => {
        return series.getCode(i) as string;
      });
      this.set(keys, i);
    }
    // levelsからkeysを構築
    this.keys = Array.from({ length: this.rowCount }, (_, i) =>
      this.levels.map((series) => {
        return series.getCode(i) as string;
      })
    );
  }

  private recalculateLevelIndex() {
    this.levelReverseMap = Object.fromEntries(
      this.levels.map((level, i) => [level.name, i])
    );
    this.levelMap = Object.fromEntries(
      this.levels.map((level) => [level.name, level])
    );
  }

  static fromKeys(keys: (string | number | null)[][], seriesList: PSeries[]) {
    const index = new MultilevelIndex();
    index.keys = keys;
    index.rowCount = keys.length;
    let formerSpans: number[] | undefined;
    index.levels = keys[0].map((_, i) => {
      const series = seriesList[i];
      const values = keys.map((key) => key[i]);
      const level = new PSeries(
        {
          name: series.name,
          type: series.type,
          dataset: series.dataset,
          categories: series.categories,
          codes: values as (number | null)[],
        },
        series.isIndex,
        formerSpans
      );
      formerSpans = level.spans;
      return level;
    });
    index.recalculateIndex();
    return index;
  }

  // keysに対する行番号をセット
  private set(keys: string[], value: number) {
    if (!this.treeIndex)
      throw new Error("Internal error. treeIndex is not enabled");
    let index = this.treeIndex;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (i === keys.length - 1) {
        index.set(key, value);
      } else if (!index.has(key)) {
        const newIndex = new Map<string, MultilevelValue<number>>();
        index.set(key, newIndex);
        index = newIndex;
      } else {
        index = index.get(key) as MultilevelMap<number>;
      }
    }
  }

  // keysに対する行番号を取得
  getRowIndex(keys: (string | number | null)[]) {
    if (!this.treeIndex)
      throw new Error("Internal error. treeIndex is not enabled");
    let index = this.treeIndex;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (i === keys.length - 1) {
        return index.get(key ?? "null") as number;
      } else if (!index.has(key ?? "null")) {
        return undefined;
      } else {
        index = index.get(key ?? "null") as MultilevelMap<number>;
      }
    }
  }

  extractLevels(levels: number[]) {
    return MultilevelIndex.fromKeys(
      extractLevelKeys(this.keys, levels),
      levels.map((level) => this.levels[level])
    );
  }

  // 内部コードを振りなおす。カテゴリーが存在しない場合は末尾に追加する
  // ソートは行わないので別途ソートが必要。
  reorderIndexes(map: Record<string, string[]>) {
    for (const [name, categories] of Object.entries(map)) {
      const level = this.levelReverseMap[name];
      if (level == null) {
        continue;
      }
      this.levels[level].reorderIndex(categories);
    }
    this.recalculateIndex();
  }

  sortIndex() {
    return MultilevelIndex.fromKeys(
      sortWithLevels(
        this.keys,
        this.levels.map((_, i) => i)
      ),
      this.levels
    );
  }
}

export class PDataFrame {
  rowCount: number;
  rowIndex: MultilevelIndex;
  columns: NamedList<PSeries>;
  indexes: NamedList<PSeries>;
  indexesAndColumns: NamedList<PSeries>;

  constructor(public data: PDataFrameData) {
    this.rowCount = data.rowCount;
    this.columns = new NamedList(
      data.columns.map((columnData) => new PSeries(columnData))
    );
    let formerSpans: number[] | undefined;
    this.indexes = new NamedList(
      data.indexes.map((columnData) => {
        const series = new PSeries(columnData, true, formerSpans);
        formerSpans = series.spans;
        return series;
      })
    );
    this.indexesAndColumns = new NamedList([...this.indexes, ...this.columns]);
    this.rowIndex = MultilevelIndex.fromLevels(Array.from(this.indexes));
  }

  reorderIndexes(map: Record<string, string[]>) {
    this.rowIndex.reorderIndexes(map);
    this.sortIndex();
  }

  sortIndex() {
    const oldRowIndex = this.rowIndex;
    this.rowIndex = this.rowIndex.sortIndex();
    this.indexes = new NamedList(this.rowIndex.levels);
    // 新しいindex -> 旧indexのマップ
    const indexMap = this.rowIndex.keys.map((keys, i) => {
      const index = oldRowIndex.getRowIndex(keys);
      if (index == null) {
        console.error(oldRowIndex);
        throw new Error("index not found");
      }
      return index;
    });
    for (const column of this.indexesAndColumns) {
      if (column.codes) {
        const codes = column.codes;
        column.codes = indexMap.map((index) => codes[index]);
      }
      if (column.values)
        column.values = indexMap.map((index) => column.values[index]);
    }
    // this.rowIndex.recalculateIndex();
  }

  pivot<T>(options: {
    columns: string[];
    rows?: string[];
    value: string;
    callback?: (
      record: Record<string, string | number | null> | number | null
    ) => T;
  }) {
    return new PPivotDataFrame(this, options);
  }
}

export class PPivotDataFrame<T = number> {
  rowIndex;
  columnIndex;
  columns;
  rows;
  keyMap: { type: "row" | "column"; index: number }[];
  value;
  callback;
  constructor(
    public dataFrame: PDataFrame,
    options: {
      columns: string[];
      rows?: string[];
      value: string;
      callback?: (
        record: Record<string, string | number | null> | number | null
      ) => T;
    }
  ) {
    for (const row of options.rows ?? []) {
      if (!dataFrame.indexes.getByName(row)) {
        console.error(dataFrame);
        throw new Error("index not found " + row);
      }
    }
    for (const column of options.columns) {
      if (!dataFrame.indexes.getByName(column)) {
        console.error(dataFrame);
        throw new Error("index not found " + column);
      }
    }
    this.value = options.value;
    this.callback = options.callback;
    // index name -> index number
    const indexMap = Object.fromEntries(
      dataFrame.rowIndex.levels.map((index, i) => [index.name, i])
    );
    this.rows =
      options.rows ??
      dataFrame.rowIndex.levels
        .map((level) => level.name)
        .filter((name) => !options.columns.includes(name));
    this.columns = options.columns;
    this.rowIndex = dataFrame.rowIndex.extractLevels(
      this.rows.map((row) => indexMap[row])
    );
    this.columnIndex = dataFrame.rowIndex.extractLevels(
      this.columns.map((column) => indexMap[column])
    );
    this.keyMap = [];
    this.rows.forEach((row, i) => {
      this.keyMap[indexMap[row]] = {
        type: "row",
        index: i,
      };
    });
    this.columns.forEach((column, i) => {
      this.keyMap[indexMap[column]] = {
        type: "column",
        index: i,
      };
    });
  }

  getValue(rowKeys: (string | number | null)[], columnKeys: string[]) {
    const keys = this.keyMap.map((desc) => {
      if (desc.type === "row") {
        return rowKeys[desc.index];
      } else {
        return columnKeys[desc.index];
      }
    });
    const rowIndex = this.dataFrame.rowIndex.getRowIndex(keys);
    if (rowIndex == null) return undefined;
    if (this.value) {
      const value = this.dataFrame.columns.getByName(this.value)?.get(rowIndex);
      return this.callback ? this.callback(value as number) : value;
    } else {
      const record = Object.fromEntries(
        this.dataFrame.indexesAndColumns.map((series, i) => [
          series.name,
          series.get(rowIndex),
        ])
      );
      return this.callback ? this.callback(record) : record;
    }
  }
}
