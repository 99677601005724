import React from "react";
import { Schema } from "schemaComponents";

export default (): Schema => {
  return {
    schemaType: "object",
    properties: [
      {
        title: "メンテナンスモード",
        propertyName: "maintenanceMode",
        schema: {
          schemaType: "boolean",
        },
        tooltip:
          "コンシューマ向け、Entry, Enquete、抽選画面をメンテナンスモードにします",
      },
      {
        title: "メンテナンスモード案内文",
        propertyName: "maintenanceModeMessage",
        schema: {
          schemaType: "multilineTextIntl",
        },
      },
      {
        title: "プライバシーポリシー",
        propertyName: "privacyPolicy",
        schema: {
          schemaType: "multilineTextIntl",
        },
      },
    ],
  };
};
