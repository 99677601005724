import React, { useEffect } from "react";
import { PDataFrame, PPivotDataFrame, PSeries } from "./PDataFrame";
import "./PTable.scss";
import classNames from "classnames";
import { set } from "date-fns";
import { formatWithCommas } from "utils/string";

const range = (length: number) => ({
  map<T>(callback: (i: number) => T) {
    const result = [];
    for (let i = 0; i < length; i++) {
      result.push(callback(i));
    }
    return result;
  },
});

export type Formatter =
  | Record<string | number, string>
  | ((value: string | number | null) => string | JSX.Element);

export type Formatters = Record<string, Formatter>;

const resolveValue = (value: string | number | null, formatter?: Formatter) => {
  if (typeof formatter === "function") {
    return formatter(value);
  } else {
    return formatter?.[value ?? "null"] || value;
  }
};

const getColummFormatter = (column: PSeries, formatters?: Formatters) => {
  if (!formatters) return undefined;
  return formatters[column.data.dataset || column.name];
};

const getColumnValue = (
  column: PSeries,
  rowIndex: number,
  formatters?: Formatters
) => {
  const value = column.get(rowIndex);
  const formatter = getColummFormatter(column, formatters);
  return resolveValue(value, formatter);
};

const containerClass = (fix?: "row" | "column" | "both") =>
  classNames({
    ptable: true,
    "ptable-stickey-row": fix === "row" || fix === "both",
    "ptable-stickey-column": fix === "column" || fix === "both",
  });

const parity = (index: number) => (index % 2 === 0 ? "even" : "odd");
const useOffsets = (
  options: { scrollToEnd?: "row" | "column" | "both" },
  deps: React.DependencyList
) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [offsets, setOffsets] = React.useState<number[]>([]);
  useEffect(() => {
    const widths = Array.from(
      ref.current?.querySelectorAll("thead th") || []
    ).map((e) => (e as HTMLElement)?.clientWidth);
    let acc = 0;
    const offsets = widths.map((width) => {
      const result = acc;
      acc += width;
      return result;
    });
    setOffsets(offsets);
  }, deps);
  if (ref.current) {
    if (options?.scrollToEnd === "column" || options?.scrollToEnd === "both")
      ref.current.scrollLeft =
        ref.current.scrollWidth - ref.current.clientWidth;
    if (options?.scrollToEnd === "row" || options?.scrollToEnd === "both")
      ref.current.scrollTop =
        ref.current.scrollHeight - ref.current.clientHeight;
  }
  return { ref, offsets };
};

export const PTable = React.memo(function PTable({
  dataFrame,
  formatters,
  fix,
  scrollToEnd,
}: {
  dataFrame: PDataFrame;
  formatters?: Formatters;
  fix?: "row" | "column" | "both";
  scrollToEnd?: "row" | "column" | "both";
}) {
  const { ref, offsets } = useOffsets({ scrollToEnd }, [dataFrame, formatters]);
  return (
    <div className={containerClass(fix)} ref={ref}>
      <table>
        <thead>
          <tr>
            {dataFrame.rowIndex.levels.map((column, rowLevelIndex) => (
              <th
                key={column.name}
                data-key={column.name}
                style={{
                  left: offsets[rowLevelIndex],
                }}
              >
                {resolveValue(column.name, formatters?.label)}
              </th>
            ))}
            {dataFrame.columns.map((column, columnIndex) => (
              <td
                key={column.name}
                data-key={column.name}
                className={classNames({
                  "ptable-level-odd": true,
                  "ptable-column-end": columnIndex === dataFrame.columns.size(),
                })}
              >
                {resolveValue(column.name, formatters?.label)}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {dataFrame.rowIndex.keys.map((rowKey, rowIndex) => (
            <tr key={rowIndex} data-key={rowIndex}>
              {dataFrame.rowIndex.levels.map((column, rowLevelIndex) => {
                const span = column.spans[rowIndex];
                return span ? (
                  <th
                    rowSpan={span}
                    key={column.name}
                    data-key={column.name}
                    className={classNames(
                      `ptable-level-${parity(rowLevelIndex)}`,
                      {
                        "ptable-row-end": rowIndex === column.spans.length - 1,
                        "ptable-column-end":
                          rowLevelIndex ===
                          dataFrame.rowIndex.levels.length - 1,
                      }
                    )}
                    style={{
                      left: offsets[rowLevelIndex],
                    }}
                  >
                    {getColumnValue(column, rowIndex, formatters)}
                  </th>
                ) : undefined;
              })}
              {dataFrame.columns.map((column) => (
                <td key={column.name} data-key={column.name}>
                  {getColumnValue(column, rowIndex, formatters)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});

export const PPivotTable = React.memo(function PTable({
  dataFrame,
  formatters,
  fix,
  scrollToEnd,
}: {
  dataFrame: PPivotDataFrame;
  formatters?: Formatters;
  fix?: "row" | "column" | "both";
  scrollToEnd?: "row" | "column" | "both";
}) {
  const { ref, offsets } = useOffsets({ scrollToEnd }, [dataFrame, formatters]);
  return (
    <div className={containerClass(fix)} ref={ref}>
      <table>
        <thead>
          {dataFrame.columnIndex.levels.map((columnLevel, columnLevelIndex) => {
            return (
              <tr key={columnLevel.name}>
                {columnLevelIndex !== 0
                  ? undefined
                  : dataFrame.rowIndex.levels.map((rowLevel, rowLevelIndex) => (
                      <th
                        key={rowLevel.name}
                        data-key={rowLevel.name}
                        rowSpan={dataFrame.columnIndex.levels.length}
                        style={{ left: offsets[rowLevelIndex] }}
                      >
                        {resolveValue(rowLevel.name, formatters?.label)}
                      </th>
                    ))}
                {dataFrame.columnIndex.keys.map((columnKey, columnIndex) => {
                  const span = columnLevel.spans[columnIndex];
                  return span ? (
                    <td
                      colSpan={span}
                      key={columnIndex}
                      data-key={columnIndex}
                      className={classNames(
                        `ptable-level-${parity(columnLevelIndex)}`,
                        {
                          "ptable-row-end":
                            columnIndex === columnLevel.spans.length - 1,
                          "ptable-column-end":
                            columnIndex ===
                            dataFrame.columnIndex.keys.length - 1,
                        }
                      )}
                    >
                      {getColumnValue(columnLevel, columnIndex, formatters)}
                    </td>
                  ) : undefined;
                })}
              </tr>
            );
          })}
        </thead>
        <tbody>
          {dataFrame.rowIndex.keys.map((rowKey, rowIndex) => {
            return (
              <tr key={rowIndex} data-key={rowIndex}>
                {dataFrame.rowIndex.levels.map((column, rowLevelIndex) => {
                  const span = column.spans[rowIndex];
                  return span ? (
                    <th
                      rowSpan={span}
                      key={column.name}
                      data-key={column.name}
                      className={classNames(
                        `ptable-level-${parity(rowLevelIndex)}`,
                        {
                          "ptable-row-end":
                            rowIndex === column.spans.length - 1,
                          "ptable-column-end":
                            rowLevelIndex ===
                            dataFrame.rowIndex.levels.length - 1,
                        }
                      )}
                      style={{ left: offsets[rowLevelIndex] }}
                    >
                      {getColumnValue(column, rowIndex, formatters)}
                    </th>
                  ) : undefined;
                })}
                {dataFrame.columnIndex.keys.map((columnKey) => {
                  const value = dataFrame.getValue(
                    rowKey,
                    columnKey as string[]
                  );
                  const formattedValue =
                    typeof value === "string" || typeof value === "number"
                      ? formatWithCommas(value)
                      : value;
                  return (
                    <td
                      key={columnKey.join(":")}
                      data-key={columnKey.join(":")}
                    >
                      {formattedValue}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
});
