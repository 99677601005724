import React from "react";
import ReactDOM from "react-dom";

import LocaleProvider from "components/LocaleProvider";
import { ModalProvider } from "react-hooks-async-modal";
import { Routes } from "./pages";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/main.scss";
import "./assets/css/reset.scss";
import "./assets/css/field.scss";
import "./assets/css/level.scss";
import "./assets/css/hero.scss";
import "./assets/css/navbar.scss";
import "./assets/css/tooltip.scss";
import "react-tooltip/dist/react-tooltip.css";

import reportWebVitals from "./reportWebVitals";

import "./utils/typedRoute";
import { BrowserRouter } from "react-router-dom";
import { Tooltip } from "react-tooltip";
const app = (
  // <React.StrictMode>
  <LocaleProvider>
    <Tooltip id="tooltip" style={{ zIndex: 1000 }} />
    <Tooltip
      id="tooltip-click"
      openEvents={{ click: true }}
      closeEvents={{ click: true }}
      style={{ zIndex: 1010 }}
    />
    <BrowserRouter>
      <ModalProvider>
        <Routes />
      </ModalProvider>
    </BrowserRouter>
  </LocaleProvider>
  // </React.StrictMode>
);

// const checkCode = "3Bdfgkadh1lZDb";
// if (
//   !isStaging ||
//   sessionStorage.getItem("staging_check") === checkCode ||
//   prompt("認証コードを入力してください") === checkCode
// ) {
//   sessionStorage.setItem("staging_check", checkCode);
//   ReactDOM.render(app, document.getElementById("root"));
// }
ReactDOM.render(app, document.getElementById("root"));

reportWebVitals(console.log);

const a = <div style={{}}></div>;
console.log("div", a, app);
