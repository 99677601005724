import React, { useRef, useState, forwardRef } from "react";
import classNames from "classnames";
import {
  SchemaComponent,
  SchemaComponentInternalProps,
  useField,
  requiredValidator,
  BaseSchema,
} from "react-hook-schema-form";
import { FieldWrapper } from "./common/fieldWrapper";
import { DisplayComponent } from "./common/displayWrapper";
import DatePicker from "react-datepicker";
import { useFormatter } from "hooks/intl";
import { Button, InputGroup } from "react-bootstrap";
import Select from "react-select";

import "./searchDate.scss";
import { dayjs } from "utils/dayjs";
import "react-datepicker/dist/react-datepicker.css";
import { useContextLocale } from "components/LocaleProvider";

interface SearchDateSchema extends BaseSchema {
  schemaType: "searchDate";
  required?: boolean;
}

export type DateRange = {
  $gte: number;
  $lt?: number;
};

export const timeZone = "Asia/Tokyo";

const timezoneOffset = dayjs.tz("1970-01-01", timeZone).valueOf();

const systemTimezoneOffset = new Date().getTimezoneOffset() * 60 * 1000;

const timezoneAdjust = timezoneOffset - systemTimezoneOffset;

console.log({ timezoneAdjust, timezoneOffset, systemTimezoneOffset });

const isMobile = navigator.userAgent.match(
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/
);

const MonthInput = forwardRef(
  (
    {
      value,
      onClick,
      className,
      onMove,
    }: {
      value: string | undefined | null;
      className: string;
      onClick: React.MouseEventHandler<HTMLDivElement>;
      onMove: (value: number) => void;
    },
    ref
  ) => (
    <div
      className={className}
      onClick={onClick}
      style={{ position: "relative", padding: "6px 30px", textAlign: "center" }}
    >
      {onMove && (
        <Button
          variant="outline"
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            borderColor: "rgb(206, 212, 218)",
            height: 38,
            border: "none",
          }}
          onClick={(e) => {
            e.stopPropagation();
            onMove(-1);
          }}
        >
          <i className="mdi mdi-chevron-left" />
        </Button>
      )}
      <div style={{ cursor: "pointer" }}>{value}</div>
      {onMove && (
        <Button
          variant="outline"
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            borderColor: "rgb(206, 212, 218)",
            height: 38,
            border: "none",
          }}
          onClick={(e) => {
            e.stopPropagation();
            onMove(1);
          }}
        >
          <i className="mdi mdi-chevron-right" />
        </Button>
      )}
    </div>
  )
);
MonthInput.displayName = "CustomDatePickerInput";

export const relativeMonth = (
  dateRange: DateRange | null | undefined,
  value: number
) => {
  const thisMonth = dayjs
    .tz(dateRange?.$gte, timeZone)
    .startOf("month")
    .add(value, "month");
  return {
    $gte: thisMonth.valueOf(),
    $lt: thisMonth.add(1, "month").valueOf(),
  };
};

const recentPeriod = (unit: dayjs.ManipulateType, value = 1) => {
  const today = dayjs.tz(Date.now(), timeZone).startOf("day").add(1, "day");
  return {
    $gte: today.subtract(value, unit).valueOf(),
    $lt: today.valueOf(),
  };
};

const dayInMilisecond = 24 * 3600 * 1000;

export const MonthPicker = ({
  dateRange,
  onChange,
  className,
}: {
  dateRange: DateRange | null;
  onChange: (value: DateRange) => void;
  className: string;
}) => {
  const onDateChangedHandler = (value: Date) => {
    onChange(relativeMonth({ $gte: value?.getTime() + timezoneAdjust }, 0));
  };
  const onMove = (value: number) => {
    onChange(relativeMonth(dateRange, value));
    setTimeout(() => {
      ref.current?.setOpen(false);
    }, 0);
  };
  const [locale] = useContextLocale();
  const { formatMessage } = useFormatter();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<DatePicker>(null);

  return (
    <DatePicker
      ref={ref}
      locale={locale}
      dateFormat={formatMessage("Calendar.YearMonthFormat")}
      dateFormatCalendar={formatMessage("Calendar.YearMonthFormat")}
      selected={dateRange?.$gte ? new Date(dateRange?.$gte) : undefined}
      onChange={onDateChangedHandler}
      onCalendarOpen={() => setIsOpen(true)}
      onCalendarClose={() => setIsOpen(false)}
      className={classNames(className, isOpen && "focused")}
      showMonthYearPicker
      customInput={
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        <MonthInput {...({} as any)} onMove={onMove} />
      }
    />
  );
};

const RangePicker = ({
  dateRange,
  onChange,
  className,
}: {
  dateRange: DateRange | null;
  onChange: (value: DateRange) => void;
  className: string;
}) => {
  const onDateChangedHandler = ([start, end]: [Date, Date]) =>
    onChange({
      $gte: start?.getTime() + timezoneAdjust,
      $lt: end?.getTime() + timezoneAdjust + dayInMilisecond,
    });
  const [locale] = useContextLocale();
  const { formatMessage } = useFormatter();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <DatePicker
      selectsRange={true}
      locale={locale}
      dateFormat={"yyyy/MM/dd"}
      dateFormatCalendar={formatMessage("Calendar.YearMonthFormat")}
      selected={dateRange?.$gte ? new Date(dateRange?.$gte) : undefined}
      startDate={dateRange?.$gte ? new Date(dateRange?.$gte) : undefined}
      endDate={
        dateRange?.$lt ? new Date(dateRange?.$lt - dayInMilisecond) : undefined
      }
      onChange={onDateChangedHandler}
      onCalendarOpen={() => setIsOpen(true)}
      onCalendarClose={() => setIsOpen(false)}
      className={classNames(className, isOpen && "focused")}
    />
  );
};

const focusedStyle = {
  borderColor: "#86b7fe",
  background: "#86b6fe20",
  boxShadow: "0 0 0 0.125rem rgb(113 170 255 / 25%)",
};
const normalStyle = {
  borderColor: "#ced4da",
  background: "none",
  boxShadow: "none",
};

type Mode =
  | "range"
  | "month"
  | "forTheLastWeek"
  | "forTheLast30Days"
  | "thisMonth"
  | "lastMonth"
  | "all";
const SearchDateSchemaComponent: SchemaComponent<SearchDateSchema> = (
  props: SchemaComponentInternalProps<SearchDateSchema>
) => {
  const { registerProps, value, fieldState } = useField(props, [
    requiredValidator,
  ]);

  const [mode, setMode] = useState<Mode | undefined>(() => {
    if (!value?.$gte || !value?.$lt) {
      return "forTheLast30Days";
    }
    const start = dayjs.tz(value?.$gte, timeZone);
    const end = dayjs.tz(value?.$lt, timeZone);
    const isMonthLike =
      start.isSame(start.startOf("month")) && start.add(1, "month").isSame(end);
    return isMonthLike ? "month" : "range";
  });

  const options = [
    { value: "forTheLastWeek", label: "直近1週間" },
    { value: "forTheLast30Days", label: "直近30日" },
    { value: "thisMonth", label: "今月" },
    { value: "lastMonth", label: "先月" },
    { value: "month", label: "月" },
    { value: "range", label: "範囲" },
    { value: "all", label: "全期間" },
  ] as { value: Mode; label: string }[];

  return (
    <FieldWrapper fieldState={fieldState}>
      <InputGroup className="searchDate">
        <Select
          options={options}
          value={options.find((option) => option.value === mode)}
          onChange={(option) => {
            const newMode = option?.value;
            if (newMode === "thisMonth") {
              registerProps.onChange(relativeMonth(null, 0));
            } else if (newMode === "lastMonth") {
              registerProps.onChange(relativeMonth(null, -1));
            } else if (newMode === "month") {
              registerProps.onChange(relativeMonth(value, 0));
            } else if (newMode === "forTheLastWeek") {
              registerProps.onChange(recentPeriod("week"));
            } else if (newMode === "forTheLast30Days") {
              registerProps.onChange(recentPeriod("day", 30));
            } else if (newMode === "all") {
              registerProps.onChange(undefined);
            }
            setMode(newMode);
          }}
          className="form-control col-sm-4 searchDateMode"
          styles={{
            control: (provided, { menuIsOpen, isFocused }) => {
              return {
                ...provided,
                "&:hover": focusedStyle,
                ...(menuIsOpen || isFocused ? focusedStyle : normalStyle),
              };
            },
          }}
        />
        {mode === "month" || mode === "thisMonth" || mode === "lastMonth" ? (
          <MonthPicker
            className={classNames("form-control", {
              "has-error": fieldState.invalid,
            })}
            dateRange={value}
            {...registerProps}
            onChange={(v) => {
              setMode("month");
              registerProps.onChange(v);
            }}
          />
        ) : (
          <RangePicker
            className={classNames("form-control", {
              "has-error": fieldState.invalid,
            })}
            dateRange={value}
            {...registerProps}
            onChange={(v) => {
              setMode("range");
              registerProps.onChange(v);
            }}
          />
        )}
      </InputGroup>
    </FieldWrapper>
  );
};

SearchDateSchemaComponent.display = DisplayComponent(
  "DateDisplayComponent",
  (value) => {
    const { formatDate } = useFormatter();
    return value ? formatDate(value) + "" : "";
  }
);

export default SearchDateSchemaComponent;
