import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  getQueryRefCache,
  createDocumentRefHook,
  UseQueryOption,
  QueryResult,
  EmptyObject,
} from "@smart-hook/react-smart-hook-firebase";
import { createStoreCacheHook } from "@smart-hook/react-hook-retention-cache";
import stringify from "fast-json-stable-stringify";
import { Query } from "mingo";
import { useOperators, OperatorType } from "mingo/core";

import { documentAccessor, queryAccessor } from "../comodel-firestore-web";
import {
  cleanObject,
  CollectionDefinitionAny,
  ConstraintKeyOf,
  DataOf,
  QueryDef,
  QueryDefResult,
} from "../common/comodel-firestore";
import {
  DocumentSnapshot,
  getDocs,
  limit,
  query,
  startAfter,
  type Query as FQuery,
} from "firebase/firestore";
import { useMemoWithPrev } from "hooks/common";

export { cleanObject };

useOperators(OperatorType.QUERY, {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $matchIntl:
    (selector: string, search: unknown) => (obj: Record<string, unknown>) => {
      console.log("###", selector, search, obj);
      const value = obj[selector];
      if (typeof search !== "string") return false;
      if (typeof value === "object") {
        return Object.values(value as Record<string, string>).some((v) =>
          typeof v === "string" ? v.includes(search) : false
        );
      } else if (typeof value === "string") {
        return value.includes(search);
      }
      return false;
    },
  $truthy:
    (selector: string, search: unknown) => (obj: Record<string, unknown>) => {
      const value = obj[selector];
      const match = typeof search === "string" ? JSON.parse(search) : !!search;
      return !!value === match;
    },
});

const useDocumentData = createDocumentRefHook();
const useQueryData = (
  (({ withData, retentionTime }: UseQueryOption = {}) =>
    createStoreCacheHook(
      getQueryRefCache({ withData, retentionTime }),
      {} as EmptyObject
    )) as (
    options?: UseQueryOption
  ) => <D>(params: FQuery<D> | undefined) => QueryResult<D> | EmptyObject
)();

const stringifyArray = (array: unknown[]) => array.map(stringify);

export const createDocumentHook = <C extends CollectionDefinitionAny>(
  collectionDef: C
) => {
  return (constraint: { [K in ConstraintKeyOf<C>]?: string }) => {
    const accessor = useMemo(() => {
      return documentAccessor(collectionDef, constraint);
    }, [stringify(constraint)]);
    const { snapshot, loading, error } = useDocumentData(accessor.ref);
    const data = useMemo(
      () => snapshot?.data() || accessor.defaultValue,
      [snapshot]
    );
    useMemo(() => {
      if (error) console.error(error);
    }, [error]);
    return {
      data,
      loading,
      error,
      ...accessor,
      // confirm to exist by checking real data
      isUpdate: !!snapshot?.exists(),
      // confirm not to exist by checking real data
      isNew: !accessor.ref || (snapshot && !snapshot?.exists()),
      // type checking only
      isEditing: !!accessor.ref,
    };
  };
};

export const createQueryHook = <
  T extends unknown[],
  C extends CollectionDefinitionAny
>(
  collectionDef: C,
  queryGenerator: QueryDef<T, ConstraintKeyOf<C>>
) => {
  return (...args: T | []) => {
    const { queryParams, constraint, filterParams } = useMemo(() => {
      const params =
        queryGenerator(...(args as T)) ||
        ({} as QueryDefResult<ConstraintKeyOf<C>>);
      cleanObject(params);
      return params;
    }, stringifyArray(args));

    const filter = useMemo(() => {
      const mingoQuery = new Query(filterParams || {});
      return mingoQuery.test.bind(mingoQuery);
    }, [stringify(filterParams)]);

    const accessor = useMemo(() => {
      return (
        queryParams &&
        queryAccessor(collectionDef, constraint || {}, queryParams)
      );
    }, stringifyArray([constraint, queryParams]));

    const { snapshot, loading, error } = useQueryData(accessor?.ref);
    const list = useMemo(
      () => snapshot?.docs.map((doc) => doc.data()).filter(filter),
      [snapshot, filter]
    );
    // console.log({ list });
    useMemo(() => {
      if (error) console.error(error);
    }, [error]);
    return {
      list,
      loading,
      error,
      ...accessor,
      get: async () => {
        const list = await accessor?.get();
        return list?.filter(filter);
      },
    };
  };
};

export const createProgressiveQueryHook = <
  T extends unknown[],
  C extends CollectionDefinitionAny
>(
  collectionDef: C,
  queryGenerator: QueryDef<T, ConstraintKeyOf<C>>
) => {
  return (...args: T | []) => {
    const maxItems = 500;
    const firstMaxItem = 100;
    const prefetchItems = 50;
    const [range, setRange] = useState<{ min?: number; max: number }>({
      max: 0,
    });
    const [list, setList] = useState<
      DataOf<C>[] & { oldListId?: number; additionalList?: DataOf<C>[] }
    >([]);
    const [lastSnapshot, setLastSnapshot] = useState<
      DocumentSnapshot<DataOf<C>> | false
    >();
    const [error, setError] = useState<Error>();
    const [fetching, setFetching] = useState<boolean>();

    const { queryParams, constraint, filterParams } = useMemo(() => {
      const params =
        queryGenerator(...(args as T)) ||
        ({} as QueryDefResult<ConstraintKeyOf<C>>);
      cleanObject(params);
      return params;
    }, stringifyArray(args));
    const filter = useMemo(() => {
      const mingoQuery = new Query(filterParams || {});
      return mingoQuery.test.bind(mingoQuery);
    }, [stringify(filterParams)]);
    const accessor = useMemo(() => {
      return (
        queryParams &&
        queryAccessor(collectionDef, constraint || {}, queryParams)
      );
    }, stringifyArray([constraint, queryParams]));
    const accessorRef = useRef<typeof accessor>(accessor);
    accessorRef.current = accessor;

    const refresh = async () => {
      if (!accessor?.ref) {
        return;
      }
      if (fetching) {
        return;
      }
      try {
        if (lastSnapshot === false) {
          return;
        }
        const currentMaxItem = lastSnapshot ? maxItems : firstMaxItem;
        const ref = lastSnapshot
          ? query(accessor.ref, startAfter(lastSnapshot), limit(currentMaxItem))
          : query(accessor.ref, limit(currentMaxItem));
        setFetching(true);
        const snapshot = await getDocs(ref);
        setFetching(false);
        if (accessorRef.current !== accessor) {
          return;
        }
        const additionalList = snapshot.docs.map((doc) => doc.data());
        setLastSnapshot(
          snapshot.docs.length === currentMaxItem
            ? snapshot.docs[snapshot.docs.length - 1]
            : false
        );
        if (additionalList.length) {
          setList(
            Object.assign([...list, ...additionalList], {
              oldListId: getObjectId(list),
              additionalList,
            })
          );
        }
      } catch (e) {
        setError(e as Error);
        setFetching(false);
      }
    };
    const filteredList = useMemoWithPrev(
      (
        oldFilteredList?: DataOf<C>[] & {
          listId: number;
          originFilter: (obj: Record<string, unknown>) => boolean;
        }
      ) => {
        const filteredList = (() => {
          if (
            oldFilteredList &&
            oldFilteredList.listId === list.oldListId &&
            oldFilteredList.originFilter === filter
          ) {
            const additionalFilteredList = oldFilteredList.filter(filter);
            if (additionalFilteredList.length) {
              return [...oldFilteredList, ...additionalFilteredList];
            } else {
              return oldFilteredList;
            }
          }
          return list.filter(filter);
        })();
        return Object.assign(filteredList, {
          listId: getObjectId(list),
          originFilter: filter,
        });
      },
      [filter, list]
    );
    const shouldRefresh = filteredList.length - range.max < prefetchItems;
    useEffect(() => {
      if (!fetching && shouldRefresh) {
        refresh();
      }
    });
    useEffect(() => {
      setList([]);
      setRange({ max: 0 });
      setLastSnapshot(undefined);
    }, [accessor]);
    useMemo(() => {
      if (error) console.error(error);
    }, [error]);
    const get = useMemo(() => {
      return async () => {
        const list = await accessor?.get();
        return list?.filter(filter);
      };
    }, [accessor]);
    const refresher = useMemo(
      () => ({ currentTime: Date.now() }),
      [filter, accessor]
    );
    const reload = useCallback(() => {
      setList([]);
      setRange({ max: 0 });
      setLastSnapshot(undefined);
    }, []);
    return {
      list: filteredList,
      loading: false,
      fetching: lastSnapshot !== false && (fetching || shouldRefresh),
      hasMore: lastSnapshot !== false,
      error,
      setRange,
      ...accessor,
      get,
      refresher,
      reload,
    };
  };
};

let currentObjectId = 0;
// eslint-disable-next-line @typescript-eslint/ban-types
const objectIdMap = new WeakMap<object, number>();
// eslint-disable-next-line @typescript-eslint/ban-types
const getObjectId = (obj: object) => {
  if (!objectIdMap.has(obj)) {
    objectIdMap.set(obj, ++currentObjectId);
  }
  return objectIdMap.get(obj) as number;
};
