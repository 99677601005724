import { ObjectSchema } from "schemaComponents";

export default ({ clientId }: { clientId: string }): ObjectSchema => {
  return {
    schemaType: "object",
    properties: [
      {
        title: "事業所名",
        propertyName: "shopName",
        schema: {
          schemaType: "textIntl",
          required: true,
        },
      },
      {
        title: "ロゴ",
        propertyName: "logoURL",
        schema: {
          schemaType: "image",
          maxHeight: 30,
        },
      },
      {
        title: "調査",
        propertyName: "surveyId",
        schema: {
          uiType: "normal",
          schemaType: "externalKey",
          resourcePath: "/survey",
          titleKey: "surveyName",
          query: { filter: { clientId } },
        },
      },
      {
        title: "アンケート開始日時",
        propertyName: "enqueteStartAt",
        schema: {
          schemaType: "datetime",
        },
      },
      {
        title: "アンケート終了日時",
        propertyName: "enqueteEndAt",
        schema: {
          schemaType: "datetime",
        },
      },
      {
        title: "住所",
        propertyName: "shopAddress",
        schema: {
          schemaType: "text",
        },
      },
      {
        title: "地域",
        propertyName: "region",
        schema: {
          schemaType: "text",
        },
      },
    ],
  };
};
