import React, { useCallback, useMemo, useState } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { SchemaInput } from "components/SchemaInput";
import Select, { GroupBase, InputActionMeta, StylesConfig } from "react-select";
import stringify from "fast-json-stable-stringify";
import { ObjectSchema, SchemaOfType, SchemaTypes } from "schemaComponents";
import { useSearchParamAccessor } from "utils/url";
import { isEqual } from "lodash";
import { usePropertyList } from "./properties";
import { OptionDefinition, schemaHandler } from "./schema";
import { useFieldAccessor } from "hooks/accessor";
import {
  StringIntlResolver,
  resolveStringIntl,
  useStringIntl,
} from "hooks/intl";
import { useAdminAuthentication } from "hooks/auth";

export type FieldCondition =
  | string
  | number
  | boolean
  | RegExp
  | { $in: (string | number | boolean)[] }
  | { $nin: [null, ""] }
  | { $regex: string }
  | { $gte: number }
  | { $lte: number }
  | Record<string, unknown>;

type IntrinsicOption = { label: string; value: string };
type ExtendedOption = IntrinsicOption & {
  fieldName: string;
  fieldLabel: string;
  optionLabel: string;
  operator?: string | undefined;
  optionValue?: unknown;
  keys?: string[];
};

const getOptionKey = (
  propertyName: string,
  operator: string | undefined,
  optionValue?: unknown
) => {
  return optionValue !== undefined
    ? `${propertyName}:${operator}:${stringify(optionValue)}`
    : `${propertyName}:${operator}`;
};

const invokeSchemaHandler = <T extends SchemaTypes>(
  handlers: {
    [K in SchemaTypes]?: (
      schema: SchemaOfType<K>,
      s: StringIntlResolver,
      searchInput?: string
    ) => (OptionDefinition | undefined)[];
  },
  schema: SchemaOfType<T>,
  s: StringIntlResolver,
  searchInput?: string
) => {
  const handler = handlers[schema.schemaType as T];
  return handler?.(schema, s, searchInput) || [];
};

export const useConditionSelector = ({
  schema,
  hideTimeCondition,
  searchParamKey,
  unwrapped,
}: {
  schema: ObjectSchema;
  hideTimeCondition?: boolean;
  searchParamKey?: string;
  unwrapped?: boolean;
}) => {
  const { role } = useAdminAuthentication();
  const isMaster = role === "master";
  const propertyList = usePropertyList(schema);
  const s = useStringIntl();

  const { optionSource, optionMap } = useMemo(() => {
    const optionSource = propertyList
      .map((property) => {
        const options = invokeSchemaHandler(schemaHandler, property.schema, s);
        return (options.filter((option) => option) as OptionDefinition[]).map(
          (option) => {
            const title =
              (property as { statName?: string }).statName ||
              resolveStringIntl(property.title);
            return {
              ...option,
              label:
                typeof option.optionLabel === "string"
                  ? `${title} : ${option.optionLabel}`
                  : undefined,
              fieldName: property.propertyName,
              fieldLabel: title,
              value: getOptionKey(
                property.propertyName,
                option.operator,
                option.optionValue
              ),
            } as OptionDefinition & ExtendedOption;
          }
        );
      })
      .flat();
    const optionMap = Object.fromEntries(
      optionSource.map((option) => [option.value, option])
    );
    return { optionSource, optionMap };
  }, [schema, propertyList]);

  const searchParamAccessor = useSearchParamAccessor<{
    c?: Record<string, FieldCondition>;
    t?: {
      $gte: number;
      $lt?: number;
    };
  }>();
  const timeConditionAccessor = useFieldAccessor(searchParamAccessor, "t");
  const conditionAccessor = useFieldAccessor<
    Record<string, Record<string, FieldCondition>>,
    string
  >(searchParamAccessor, searchParamKey || "c");
  const [searchInput, setSearchInput] = useState<string>();

  // <Select>に表示するためのoptionsを生成
  const options = useMemo(() => {
    const options = Array.from(
      (function* () {
        for (const optionItem of optionSource) {
          if (optionItem.handler) {
            if (!searchInput) continue;
            const custom = optionItem.handler(searchInput);
            if (custom) {
              const option = {
                optionValue: searchInput,
                ...custom,
                ...optionItem,
              };
              option.label = `${option.fieldLabel} : ${option.optionLabel}`;
              option.value = getOptionKey(
                option.fieldName,
                option.operator,
                option.optionValue
              );
              yield option as ExtendedOption;
            }
          } else {
            yield optionItem as ExtendedOption;
          }
        }
      })()
    );
    return options;
  }, [optionSource, searchInput]);

  // <Select>に表示するための、現在選択中のオプション
  const conditionOptions = useMemo(() => {
    const populateOption = (
      _option: OptionDefinition & ExtendedOption,
      optionValue: unknown
    ) => {
      const handler = _option.valueHandler || _option.handler;
      if (handler) {
        const option = {
          optionValue,
          ...handler.call(_option, optionValue as string),
          ..._option,
        };
        option.label = `${option.fieldLabel} : ${option.optionLabel}`;
        option.value = getOptionKey(
          option.fieldName,
          option.operator,
          option.optionValue
        );
        return option as ExtendedOption;
      } else {
        return _option;
      }
    };
    return Array.from(
      (function* () {
        for (const [fieldName, fieldValue] of Object.entries(
          conditionAccessor.value || {}
        )) {
          if (isObject(fieldValue)) {
            for (const [operator, optionValue] of Object.entries(fieldValue)) {
              if (operator === "$in") {
                const options = Array.from(
                  (function* () {
                    for (const optionItem of optionValue as unknown[]) {
                      const optionKey = getOptionKey(
                        fieldName,
                        undefined,
                        optionItem
                      );
                      const _option = optionMap[optionKey];
                      if (_option) {
                        yield _option as ExtendedOption;
                      }
                    }
                  })()
                );
                if (options.length === 1) {
                  for (const option of options) {
                    yield option;
                  }
                } else if (options.length > 0) {
                  const option = options[0];
                  const { fieldName, fieldLabel } = option;
                  const operator = "$in";
                  const optionValue = options.map(
                    (option) => option.optionValue
                  );
                  yield {
                    fieldName,
                    fieldLabel,
                    optionLabel: "multiple",
                    operator,
                    optionValue,
                    label: `${fieldLabel} : ${options
                      .map((option) => option.optionLabel)
                      .join(" or ")}`,
                    keys: options.map((option) => option.value),
                    value: getOptionKey(fieldName, operator, optionValue),
                  } as ExtendedOption;
                }
              } else {
                const optionKey = getOptionKey(
                  fieldName,
                  operator,
                  optionValue
                );
                if (optionMap[optionKey]) {
                  yield optionMap[optionKey] as ExtendedOption;
                } else {
                  const optionKey = getOptionKey(fieldName, operator);
                  const _option = optionMap[optionKey];
                  if (_option) {
                    yield populateOption(_option, optionValue);
                  }
                }
              }
            }
          } else {
            const optionValue = fieldValue;
            const optionKey = getOptionKey(fieldName, undefined, optionValue);
            if (optionMap[optionKey]) {
              yield optionMap[optionKey] as ExtendedOption;
            } else {
              const optionKey = getOptionKey(fieldName, undefined);
              const _option = optionMap[optionKey];
              if (_option) {
                yield populateOption(_option, optionValue);
              }
            }
          }
        }
      })()
    );
  }, [conditionAccessor.value, optionMap]);

  const onChangeCondition = useCallback(
    (options: readonly ExtendedOption[]) => {
      const condition: Record<string, FieldCondition> = {};
      for (const option of options) {
        const { fieldName, fieldLabel, optionLabel, operator, optionValue } =
          option;
        let fieldCondition = condition[fieldName] as
          | string
          | number
          | Record<string, unknown>
          | undefined;
        if (operator) {
          if (
            isObject(fieldCondition) &&
            isEqual(fieldCondition[operator], optionValue)
          ) {
            if (fieldCondition) delete fieldCondition[operator];
          } else {
            if (!fieldCondition || typeof fieldCondition !== "object") {
              fieldCondition = {};
            }
            fieldCondition[operator] = optionValue;
          }
        } else {
          if (isObject(fieldCondition) && fieldCondition.$in) {
            const arrayCondition = fieldCondition.$in as unknown[];
            if (arrayCondition.includes(optionValue)) {
              if (!fieldCondition || typeof fieldCondition !== "object") {
                fieldCondition = {};
              }
              fieldCondition.$in = arrayCondition.filter(
                (item) => item !== optionValue
              );
            } else {
              arrayCondition.push(optionValue as string | number);
            }
          } else {
            if (fieldCondition === optionValue) {
              fieldCondition = undefined;
            } else if (
              isObject(fieldCondition) ||
              fieldCondition === undefined
            ) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              fieldCondition = optionValue as any;
            } else {
              fieldCondition = { $in: [fieldCondition, optionValue] };
            }
          }
        }
        if (fieldCondition !== undefined) {
          condition[fieldName] = fieldCondition;
        } else {
          delete condition[fieldName];
        }
      }
      conditionAccessor.setValue(condition);
    },
    [conditionAccessor]
  );

  // 現在選択されているOptionKey
  const selectedOptionKeys = useMemo(() => {
    return new Set(
      conditionOptions
        .map((option) => [
          ...(option.keys ? (option.keys as string[]) : []),
          ...(option.value ? [option.value] : []),
        ])
        .flat()
    );
  }, [conditionOptions]);

  const styles: StylesConfig<
    ExtendedOption,
    true,
    GroupBase<ExtendedOption>
  > = useMemo(() => {
    return {
      multiValueLabel: (provided, state) => {
        return { ...provided, whiteSpace: "inherit" };
      },
      option: (provided, state) => {
        const selected = selectedOptionKeys.has(state.data.value);
        return {
          ...provided,
          ...(selected
            ? {
                color: "hsl(0, 0%, 100%)",
                background: "#2684FF",
                ":active": { backgroundColor: "#2684FF" },
              }
            : {}),
        };
      },
    };
  }, [selectedOptionKeys]);
  const onInputChange = useCallback(
    (value: string, actionMeta: InputActionMeta) => {
      if (actionMeta.action !== "set-value") {
        setSearchInput(value);
      }
    },
    []
  );
  const conditionSelector = useMemo(() => {
    const selector = (
      <Select
        className="conditionHeader"
        isMulti={true}
        options={options}
        value={conditionOptions}
        onChange={onChangeCondition}
        onInputChange={onInputChange}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        styles={styles}
        blurInputOnSelect={false}
        inputValue={searchInput}
        placeholder="検索条件を入力"
      />
    );
    if (hideTimeCondition && unwrapped) {
      return selector || <></>;
    }
    if (hideTimeCondition) {
      return (
        <Form.Group as={Row} style={{ padding: "10px 20px 20px 20px" }}>
          <Col sm="6">{selector}</Col>
        </Form.Group>
      );
    }
    return (
      <Form.Group as={Row} style={{ padding: "10px 20px 20px 20px" }}>
        <Form.Label column sm="1">
          検索条件
        </Form.Label>
        {selector && <Col sm="6">{selector}</Col>}
        <Col sm="5">
          <SchemaInput
            data={timeConditionAccessor.value}
            schema={{ schemaType: "searchDate" }}
            onValueChange={timeConditionAccessor.setValue}
          />
        </Col>
      </Form.Group>
    );
  }, [
    isMaster,
    options,
    conditionOptions,
    timeConditionAccessor.value,
    timeConditionAccessor.setValue,
  ]);

  return {
    condition: conditionAccessor.value,
    timeCondition: timeConditionAccessor.value,
    conditionSelector,
  };
};

const isObject = (o: unknown): o is Record<string, unknown> =>
  !!o && typeof o === "object";
