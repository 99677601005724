import { useSessionStorage } from "hooks/storage";
import React, { useContext } from "react";
import {
  searchParamLocale,
  browserLocale,
  Locale,
  getNeraestLocale,
} from "utils/locale";

const LocaleContext = React.createContext<
  [Locale, (locale: Locale) => void] | null
>(null);

export const useContextLocale = () => {
  return useContext(LocaleContext) as [Locale, (locale: Locale) => void];
};

const LocaleProvider: React.FC<{
  localeStorageKey?: string;
  availableLocales?: Locale[];
}> = ({
  children,
  localeStorageKey = "locale",
  availableLocales = ["ja"],
}: {
  children?: React.ReactNode;
  localeStorageKey?: string;
  availableLocales?: Locale[];
}) => {
  const [locale, setLocale] = useSessionStorage(
    localeStorageKey,
    searchParamLocale || browserLocale,
    Boolean(searchParamLocale)
  );
  return (
    <LocaleContext.Provider
      value={[getNeraestLocale(locale, availableLocales), setLocale]}
    >
      {children}
    </LocaleContext.Provider>
  );
};

export default LocaleProvider;
