import React from "react";
import { Schema } from "schemaComponents";
import { clientDomain } from "utils/domain";
import { resolveRoute } from "pages";
import { availableLocaleOptions, availableLocales } from "utils/locale";

export default ({
  isEditing,
  clientId,
  shopId,
  isMaster,
  isAuthSkipped,
}: {
  isEditing: boolean;
  clientId: string;
  shopId?: string;
  isMaster: boolean;
  isAuthSkipped: boolean;
}): Schema => {
  return {
    schemaType: "object",
    properties: [
      {
        title: "事業所名",
        propertyName: "shopName",
        schema: {
          schemaType: "textIntl",
          required: true,
        },
      },
      {
        title: "ロゴ",
        propertyName: "logoURL",
        schema: {
          schemaType: "image",
          rootPath: clientId ? `clients/${clientId}` : "images",
          maxHeight: 250,
        },
      },
      {
        title: "署名",
        propertyName: "shopSignature",
        schema: {
          schemaType: "multilineText",
        },
      },
      {
        title: "メール送信元",
        propertyName: "shopSender",
        schema: {
          schemaType: "text",
          required: true,
        },
      },
      ...(isMaster
        ? [
            {
              title: "事業所名をヘッダーに表示",
              propertyName: "showShopNameInHeader",
              schema: {
                schemaType: "boolean",
              },
            },
          ]
        : []),
      ...(isMaster
        ? [
            {
              title: "エントリーページタイトル",
              propertyName: "entryTitle",
              schema: { schemaType: "textIntl" },
            },
          ]
        : []),
      ...(isMaster
        ? [
            {
              title: "エントリーページサブタイトル",
              propertyName: "entrySubtitle",
              schema: { schemaType: "textIntl" },
            },
          ]
        : []),
      {
        title: "調査",
        propertyName: "surveyId",
        schema: {
          uiType: "normal",
          schemaType: "externalKey",
          resourcePath: "/survey",
          titleKey: "surveyName",
          query: { filter: { clientId } },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          href: (value: any, data: Record<string, string>) => {
            const { clientId } = data;
            return clientId && value
              ? resolveRoute("UPDATE_SURVEY", { clientId, surveyId: value })
              : "";
          },
        },
      },
      ...(isMaster
        ? [
            {
              title: "リワード条件",
              propertyName: "rewardCondition",
              schema: {
                schemaType: "array",
                defaultValue: {
                  probability: null,
                  rewardId: null,
                  animationId: null,
                },
                item: {
                  schemaType: "object",
                  // uiType: "dialog",
                  properties: [
                    {
                      title: "リワード",
                      propertyName: "rewardId",
                      schema: {
                        schemaType: "externalKey",
                        uiType: "normal",
                        resourcePath: "/reward",
                        titleKey: "rewardName",
                        query: { filter: { clientId } },
                      },
                    },
                    {
                      title: "確率",
                      propertyName: "probability",
                      schema: {
                        schemaType: "float",
                      },
                    },

                    {
                      title: "当選アニメーションパターン",
                      propertyName: "animationId",
                      schema: {
                        schemaType: "selector",
                        uiType: "normal",
                        options: [
                          {
                            title: "1等 (ラッキーセブン)",
                            value: 1,
                          },
                          {
                            title: "2等 (赤い果実)",
                            value: 2,
                          },
                          {
                            title: "3等 (ベル)",
                            value: 3,
                          },
                          {
                            title: "4等 (青い果実)",
                            value: 4,
                          },
                          {
                            title: "5等 (オレンジ)",
                            value: 5,
                          },
                          {
                            title: "はずれ（そろわない）",
                            value: 0,
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            },
          ]
        : // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ([] as any[])),
      {
        title: "抽選をスキップする",
        propertyName: "isAuthSkipped",
        schema: {
          schemaType: "boolean",
        },
      },
      ...(!isAuthSkipped
        ? [
            {
              title: "エントリーURL",
              propertyId: "entryURL",
              schema: {
                schemaType: "url",
                readOnly: true,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                calc: (item: any) => {
                  const { shopId } = item;
                  return (
                    shopId &&
                    `${clientDomain}${resolveRoute("CONSUMER_ENTRY", {
                      shopId,
                    })}`
                  );
                },
              },
            },
          ]
        : []),
      ...(isAuthSkipped
        ? [
            {
              title: "公開アンケートURL",
              propertyId: "publicEnqueteURL",
              schema: {
                schemaType: "url",
                readOnly: true,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                calc: (item: any) => {
                  const { shopId } = item;
                  return (
                    shopId &&
                    `${clientDomain}${resolveRoute("CONSUMER_PUBLIC_ENQUETE", {
                      shopId,
                    })}`
                  );
                },
              },
            },
          ]
        : []),
      {
        title: "アンケート開始日時",
        propertyName: "enqueteStartAt",
        schema: {
          schemaType: "datetime",
        },
      },
      {
        title: "アンケート終了日時",
        propertyName: "enqueteEndAt",
        schema: {
          schemaType: "datetime",
        },
      },
      {
        title: "住所",
        propertyName: "shopAddress",
        schema: {
          schemaType: "text",
        },
      },
      {
        title: "地域",
        propertyName: "region",
        schema: {
          schemaType: "text",
        },
      },
      // {
      //   title: "言語",
      //   propertyName: "availableLocales",
      //   schema: {
      //     schemaType: "multipleSelector",
      //     uiType: "normal",
      //     options: availableLocaleOptions,
      //   },
      // },
      ...(isMaster
        ? [
            {
              title: "無視",
              propertyName: "ignore",
              schema: { schemaType: "boolean" },
            },
          ]
        : []),
      {
        title: "アンケート完了テキスト",
        propertyName: "enqueteCompletedText",
        schema: {
          schemaType: "multilineTextIntl",
        },
      },
      {
        title: "アンケート完了後リンク",
        propertyName: "enqueteCompleteLinks",
        schema: {
          schemaType: "array",
          item: {
            schemaType: "object",
            properties: [
              {
                title: "タイプ",
                propertyName: "type",
                schema: {
                  schemaType: "selector",
                  uiType: "normal",
                  options: [
                    {
                      title: "TripAdvisor",
                      value: "tripAdvisor",
                    },
                    {
                      title: "Google Map",
                      value: "googleMap",
                    },
                  ],
                },
              },
              // {
              //   title: "タイトル",
              //   propertyName: "title",
              //   schema: {
              //     schemaType: "text",
              //   },
              // },
              {
                title: "URL",
                propertyName: "url",
                schema: {
                  schemaType: "url",
                },
              },
            ],
          },
        },
      },
      ...(isMaster
        ? [
            {
              title: "clientId",
              propertyName: "clientId",
              schema: {
                schemaType: "text",
                readOnly: true,
              },
            },
          ]
        : []),
    ],
  };
};
