import React, { useState, useCallback, useEffect } from "react";
import classNames from "classnames";
import { useFormatter } from "hooks/intl";
import defaultLogo from "assets/user-default-log.svg";
import { useDomEvent } from "hooks/layout";
import { useAuthentication, signOut, useAdminAuthentication } from "hooks/auth";
import { environmentName, environmentType } from "utils/project";

const NavBar = ({
  handleMobileToggle,
  asideMobileActive,
}: {
  handleMobileToggle: React.MouseEventHandler<HTMLAnchorElement> | undefined;
  asideMobileActive: boolean;
}) => {
  const { formatMessage } = useFormatter();
  const [navMobileActive, setNavMobileActive] = useState(false);

  // const { data: user } = useAuthentication();
  const { loading, isAdmin, user } = useAdminAuthentication();
  const { displayName: userName, photoURL: logoUrl, role } = user || {};

  const onClickLogoutHandler = () => {
    signOut();
  };

  const onMobileToggleHandler = useCallback(() => {
    setNavMobileActive(!navMobileActive);
  }, [setNavMobileActive, navMobileActive]);

  useDomEvent(window, ["resize", "scroll"], () => {
    setNavMobileActive(false);
  });

  return (
    <nav
      id="navbar-main"
      className={classNames("navbar", asideMobileActive && "has-aside")}
    >
      <a
        className="navbar-item is-hidden-desktop jb-aside-mobile-toggle"
        onClick={handleMobileToggle}
      >
        <span className="icon">
          <i
            className={classNames(
              "mdi",
              "mdi-24px",
              { "mdi-forwardburger": !asideMobileActive },
              { "mdi-backburger": asideMobileActive }
            )}
          />
        </span>
      </a>
      <a
        className="navbar-item is-hidden-desktop jb-navbar-menu-toggle"
        data-target="navbar-menu"
        onClick={onMobileToggleHandler}
      >
        <span className="icon">
          <i
            className={classNames(
              "mdi",
              { "mdi-dots-vertical": !navMobileActive },
              { "mdi-close": navMobileActive }
            )}
          />
        </span>
      </a>
      <img
        style={{
          height: "49px",
          padding: "7px 6px 5px 6px",
          marginLeft: "10px",
        }}
        src="/FancrewDashboard_CR.svg"
      />
      {role === "master" && (
        <div
          style={{
            margin: "0 5px",
            padding: "5px 8px",
            background: "#eee",
            borderRadius: "10px",
            alignSelf: "center",
            fontWeight: "bold",
            fontSize: "80%",
            color: "#666",
          }}
        >
          マスター
        </div>
      )}
      <div
        className={classNames("navbar-menu", {
          "is-active": navMobileActive,
        })}
        id="navbar-menu"
      >
        <div className="navbar-end">
          <div className="navbar-item has-dropdown has-dropdown-with-icons has-divider has-user-avatar is-hoverable">
            <a
              className="navbar-link is-arrowless"
              style={{ minWidth: "120px" }}
            >
              <div className="is-user-avatar">
                <img src={logoUrl || defaultLogo} alt="User profile" />
              </div>
              <div className="is-user-name">
                <span>{userName}</span>
              </div>
              <span className="icon">
                <i className="mdi mdi-chevron-down" />
              </span>
            </a>
            <div className="navbar-dropdown">
              {environmentType !== "production" && (
                <div className="navbar-item">
                  <span>環境: {environmentName}</span>
                </div>
              )}
              <div className="navbar-item">
                <span>
                  権限: {role === "master" ? "マスター" : "クライアント"}
                </span>
              </div>
              <div className="navbar-item">
                <span>バージョン: {process.env.GITHUB_COMMIT_ID}</span>
              </div>
              <hr className="navbar-divider" />
              <a
                className="navbar-item"
                id="logout"
                onClick={onClickLogoutHandler}
              >
                <span className="icon">
                  <i className="mdi mdi-logout" />
                </span>
                <span>{formatMessage("NavBar.logOut")}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
