import React from "react";
import { shopData } from "common/models/shop";
import ConsumerLayout from "components/ConsumerLayout";
import { CONSUMER } from "..";
import { useFormatter, useStringIntl } from "hooks/intl";
import { useLocation } from "react-router-dom";
import { useShopDoc } from "models/hook";
import { ConsumerReview } from "./review";
import { useTrackingLog } from "utils/log";

export const CONSUMER_ENQUETE_COMPLETED = CONSUMER.child(
  "/enqueteCompleted",
  EnqueteCompleted
);

// const template = `
// こちらから各種口コミサイトへの投稿も可能です
// {{links}}
// 必要な場合は、下記のコメントをコピーしてお使いください。
// {{comment}}
// `;

function EnqueteCompleted({
  shopId,
  shopData: injectedShopData,
}: {
  shopId?: string;
  shopData?: Partial<shopData> | null;
}) {
  useTrackingLog("enqueteComplete", { shopId }, !!injectedShopData);

  const location = useLocation();
  const { data: fetchedShopData, loading: shopDataFetching } = useShopDoc({
    shopId,
  });
  const shopData = injectedShopData || fetchedShopData;
  const shopDataLoading = injectedShopData ? false : shopDataFetching;
  // const { data: clientData } = useClientDoc({ clientId: shopData?.clientId });
  const commentData: string | undefined = location.state?.commentData;
  console.log("state", location.state);
  const { formatMessage } = useFormatter();
  const s = useStringIntl();
  // 以下のユースケースでは通常、formatString が使えるが、formatStringだと文字列が{{}}を含むためエラーになる。
  const template = !shopDataLoading
    ? s(shopData?.enqueteCompletedText || "") ||
      formatMessage("EnqueteComplete.Text")
    : "";
  return (
    <ConsumerLayout shopId={shopId} shopData={shopData} skipAuthentication>
      <div className="wide-form">
        <section className="hero">
          <div className="hero-body">
            <h1 className="title">{formatMessage("EnqueteComplete.Title")}</h1>
            <ConsumerReview
              template={template}
              shopData={shopData}
              commentData={commentData}
            />
          </div>
        </section>
      </div>
    </ConsumerLayout>
  );
}
export default EnqueteCompleted;
