import en from "languages/en.json";
import ja from "languages/ja.json";
import zhTw from "languages/zh-tw.json";
import type { Locale, StringIntl } from "common/models/type";
export type { StringIntl, Locale } from "common/models/type";

export const messages = {
  en,
  ja,
  zh: zhTw,
  // "zh-tw": zhTw,
};

export const languageMapper: Record<AvailableLocale, string> = {
  ja: "日本語",
  en: "英語",
  zh: "中国語",
};

export type AvailableLocale = keyof typeof messages;

export type MessageId = keyof (typeof messages)[AvailableLocale];

export const availableLocales = (Object.keys(messages) as AvailableLocale[])
  .map((locale) => {
    return [locale === "ja" ? "" : locale, locale] as const;
  })
  .sort((a, b) => {
    return a[0].localeCompare(b[0]);
  })
  .map((a) => a[1]);

export const availableLocaleOptions = availableLocales.map((locale) => {
  return {
    value: locale as AvailableLocale,
    title: messages[locale as AvailableLocale]?.Language,
  };
});

const defaultLocale = "ja";

export const getNeraestLocale = (
  locale?: string,
  availableLocales = Object.keys(messages) as Locale[]
) => {
  if (!locale) {
    return defaultLocale;
  }
  if (availableLocales.includes(locale)) {
    return locale;
  }
  const lang = locale.split(/[-_]/)[0];
  if (availableLocales.includes(lang)) {
    return lang;
  }
  return defaultLocale;
};

export const searchParamLocale = getNeraestLocale(
  new URLSearchParams(window.location.search).get("locale") || undefined
);

export const browserLocale = getNeraestLocale(navigator.language) || "ja";

export const getMultiLocaleValues = (multiLocalteValues: {
  [key: string]: string | undefined;
}): StringIntl | undefined => {
  const { ja, ...multiLocaleValuesExceptJa } = multiLocalteValues;
  return Object.values(multiLocaleValuesExceptJa).every((v) => !v)
    ? ja
    : multiLocalteValues;
};
